import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DevisService } from 'src/app/Services/devis.service';
import { UserService } from 'src/app/Services/user-service.service';
import { VilleService } from 'src/app/Services/ville.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  devisForm: FormGroup;
  transporteurs: any[] = [];
  filteredTransporteurs: any[] = [];
  villesList: any[] = [];


  message: string = '';

  // Pagination
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;
  constructor(
    private fb: FormBuilder,
    private devisService: DevisService,
    private router: Router,
    private userService: UserService,
    private villeService: VilleService
  ) {
    this.devisForm = this.fb.group({
      villeDepart: ['', Validators.required],
      villeArrivee: ['', Validators.required],
      nomClient: ['', Validators.required],
      telephoneClient: ['', Validators.required],
      emailClient: ['', [Validators.required, Validators.email]],
      dateLivraisonSouhaitee: ['', Validators.required],
      ligneEnvoi: this.fb.array([]), 
      idsTransporteur: this.fb.array([])
    });
  }

  get ligneEnvoi(): FormArray {
    return this.devisForm.get('ligneEnvoi') as FormArray;
  }

  addLigneEnvoi() {
    Swal.fire({
      title: 'Ajouter une ligne',
      html: `
        <form id="swal-form" style="font-family: 'Roboto', sans-serif;">
          <div style="display: flex; flex-wrap: wrap; gap: 1.5rem;">
            <!-- Description -->
            <div style="flex: 1 1 100%; margin-bottom: 1rem;">
              <label for="descriptionMarchandise" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Description de la marchandise
              </label>
              <textarea 
                id="descriptionMarchandise" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem; transition: border 0.2s;" 
                rows="4" 
                placeholder="Saisir la description ici">
              </textarea>
            </div>
  
            <!-- Type d'emballage -->
            <div style="flex: 1 1 100%; margin-bottom: 1rem;">
              <label for="typeEmbalage" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Type d'emballage
              </label>
              <select 
                id="typeEmbalage" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem; transition: border 0.2s;">
                <option value="" selected disabled>Choisir un type</option>
                <option value="COLIS">COLIS</option>
                <option value="FUTS  EN PLASTIC">FUTS  EN PLASTIC</option>
                <option value="FUTS  EN METAL">FUTS  EN METAL</option>
                <option value="PALETTE(S) STANDARD">PALETTE(S) STANDARD</option>
                <option value="CAISSE(S) BOIS  OU METAL">CAISSE(S) BOIS  OU METAL</option>
                <option value="SACS">SACS</option>
              </select>
            </div>
  
            <!-- Quantité et Poids -->
            <div style="flex: 1 1 calc(50% - 0.75rem); min-width: 45%; margin-bottom: 1rem;">
              <label for="quantite" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Quantité
              </label>
              <input 
                id="quantite" 
                type="number" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem;" 
                placeholder="Ex. 10">
            </div>
            <div style="flex: 1 1 calc(50% - 0.75rem); min-width: 45%; margin-bottom: 1rem;">
              <label for="poids" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Poids (kg)
              </label>
              <input 
                id="poids" 
                type="number" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem;" 
                placeholder="Ex. 50">
            </div>
  
            <!-- Dimensions -->
            <div style="flex: 1 1 calc(33.33% - 0.75rem); min-width: 30%; margin-bottom: 1rem;">
              <label for="longeur" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Longueur (cm)
              </label>
              <input 
                id="longeur" 
                type="number" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem;" 
                placeholder="Ex. 100">
            </div>
            <div style="flex: 1 1 calc(33.33% - 0.75rem); min-width: 30%; margin-bottom: 1rem;">
              <label for="largeur" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Largeur (cm)
              </label>
              <input 
                id="largeur" 
                type="number" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem;" 
                placeholder="Ex. 50">
            </div>
            <div style="flex: 1 1 calc(33.33% - 0.75rem); min-width: 30%; margin-bottom: 1rem;">
              <label for="hoteur" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Hauteur (cm)
              </label>
              <input 
                id="hoteur" 
                type="number" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem;" 
                placeholder="Ex. 30">
            </div>
  
            <!-- Produit dangereux -->
            <div style="flex: 1 1 100%; margin-bottom: 1rem;">
              <label for="produitDangereux" style="font-weight: bold; margin-bottom: 0.5rem; display: block; color: #555555;">
                Produit dangereux
              </label>
              <select 
                id="produitDangereux" 
                style="width: 100%; border: 1px solid #dddddd; border-radius: 10px; padding: 10px; background-color: #f9f9f9; font-size: 1rem;">
                <option value="Non" selected>Non</option>
                <option value="Oui">Oui</option>
              </select>
            </div>
          </div>
        </form>
      `,
      showCancelButton: true,
      confirmButtonText: 'Ajouter',
      cancelButtonText: 'Annuler',
      customClass: {
        popup: 'swal2-modern-popup',
        confirmButton: 'swal2-modern-confirm',
        cancelButton: 'swal2-modern-cancel',
      },
      preConfirm: () => {
        const form = document.getElementById('swal-form') as HTMLFormElement;
        return {
          descriptionMarchandise: (form.querySelector('#descriptionMarchandise') as HTMLTextAreaElement).value,
          typeEmbalage: (form.querySelector('#typeEmbalage') as HTMLSelectElement).value,
          quantite: (form.querySelector('#quantite') as HTMLInputElement).value,
          poids: (form.querySelector('#poids') as HTMLInputElement).value,
          longeur: (form.querySelector('#longeur') as HTMLInputElement).value,
          largeur: (form.querySelector('#largeur') as HTMLInputElement).value,
          hoteur: (form.querySelector('#hoteur') as HTMLInputElement).value,
          produitDangereux: (form.querySelector('#produitDangereux') as HTMLSelectElement).value,
        };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const newLigne = this.fb.group(result.value);
        this.ligneEnvoi.push(newLigne);
      }
    });
  }
  

  removeLigneEnvoi(index: number) {
    this.ligneEnvoi.removeAt(index);
  }


  ngOnInit(): void {
    this.userService.getUsers().subscribe(
      users => {
        this.transporteurs = users.filter(user => user.role === 'Transporteur');
        this.updateFilteredTransporteurs();
        this.totalPages = Math.ceil(this.transporteurs.length / this.itemsPerPage);
        this.addTransporteursCheckboxes();
      },
      error => console.error('Erreur lors de la récupération des transporteurs:', error)
    );

    this.villeService.getVilles().subscribe(data => {
      this.villesList = data;
      if (this.villesList.length > 0) {
        this.setDefaultVilleDepart();
      }
    });
  }

  private addTransporteursCheckboxes() {
    this.idsTransporteur.clear();
    this.filteredTransporteurs.forEach(() => this.idsTransporteur.push(new FormControl(false)));
  }

  get idsTransporteur() {
    return this.devisForm.get('idsTransporteur') as FormArray;
  }

  setDefaultVilleDepart(): void {
    const firstVille = this.villesList[0];
    this.devisForm.patchValue({
      villeDepart: `${firstVille.codePostal}, ${firstVille.ville}`
    });

    // Simuler un événement de changement pour mettre à jour les transporteurs
    this.onVilleDepartChange({ target: { value: this.devisForm.value.villeDepart } });
  }

  onVilleDepartChange(event: any): void {
    const selectedVille = event.target.value;
    if (selectedVille) {
      const selectedCodePostal = selectedVille.split(',')[0].trim();
      this.filteredTransporteurs = this.transporteurs.filter(transporteur =>
        transporteur.city && transporteur.city.includes(selectedCodePostal)
      );
      this.addTransporteursCheckboxes();
      this.message = this.filteredTransporteurs.length === 0 
        ? 'Aucun transporteur affiché pour la ville de départ sélectionnée.' 
        : '';
    } else {
      this.filteredTransporteurs = this.transporteurs;
      this.addTransporteursCheckboxes();
    }
  }

  generateCode(): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
    for (let i = 0; i < 8; i++) {
      code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    console.log('Generated Code:', code);
    return code;
  }

  onSubmit(): void {
    if (this.devisForm.valid) {
      const selectedTransporteursIds = this.devisForm.value.idsTransporteur
        .map((checked: boolean, i: number) => checked ? this.filteredTransporteurs[i].id : null)
        .filter((v: number | null) => v !== null);
        
      const codeReponseDevis = this.generateCode();

      const formValue = {
        ...this.devisForm.value,
        codeReponseDevis: codeReponseDevis,
        idsTransporteur: selectedTransporteursIds
      };
      
      console.log("formValue idsTransporteur :", formValue);

       this.devisService.createDevis(formValue).subscribe(
        () => {
          Swal.fire({
            icon: 'success',
            title: 'Devis envoyé avec succès',
            html: `Voici votre code pour suivre la réponse : <a href="/reponse-devis/${codeReponseDevis}">${codeReponseDevis}</a>`
          }).then(() => {
            this.router.navigateByUrl(`reponse-devis/${codeReponseDevis}`);
          });
        },
        error => {
          console.error('Erreur lors de la création du devis:', error);
          Swal.fire('Erreur', 'Erreur lors de la création du devis. Veuillez vérifier les détails et réessayer.', 'error');
        }
      );
    }
  }

  viewTransporteur(transporteur: any) {
    Swal.fire({
      title: 'Informations du transporteur',
      html: `
        <div><strong>Prénom:</strong> ${transporteur.firstName}</div>
        <div><strong>Nom:</strong> ${transporteur.lastName}</div>
        <div><strong>Email:</strong> ${transporteur.email}</div>
        <div><strong>Ville:</strong> ${transporteur.city}</div>
        <div><strong>Adresse:</strong> ${transporteur.address}</div>
        <div><strong>Téléphone:</strong> ${transporteur.phoneNumber}</div>
        <div><strong>CIN:</strong> ${transporteur.cin}</div>
      `,
      icon: 'info',
      confirmButtonText: 'OK'
    });
  }

  updateFilteredTransporteurs(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.filteredTransporteurs = this.transporteurs.slice(startIndex, endIndex);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updateFilteredTransporteurs();
    }
  }

}
