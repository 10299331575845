<div class="container">
        
    
  <h1 class="my-4 text-center">Liste des véhicules</h1>

 
  
<div class="row mb-3 ">
<div class="col text-end">
<button class="btn btn-outline-primary mt-3" (click)="addVehicule()">
  <i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter
</button>
</div> </div>  
<div class="row mb-5">
<div class="col">
  <input type="text" [(ngModel)]="searchText" class="form-control search-input" placeholder="Rechercher..." />
</div>
<div class="col text-end">
  Total d'éléments : <span class="badge badge-pill badge-primary">{{ vehicules.length }}</span> 
</div>
</div>

  <div class="row">
    <div class="col-md-4 mb-4" *ngFor="let vehicule of vehicules  | paginate: { itemsPerPage: 6, currentPage: page, totalItems: totalLength } | filter:searchText">
      <div class="card h-100 shadow-sm">
        <img *ngIf="vehicule.image" [src]="'data:image/jpeg;base64,' + vehicule.image.picByte" class="card-img-top" alt="Image du véhicule">
        <div class="card-body">
          <h5 class="card-title">{{ vehicule.marque }} - {{ vehicule.modele }}</h5>
          <p class="card-text"><strong>Immatriculation :</strong> {{ vehicule.immatriculation }}</p>
          <p class="card-text"><strong>Statut :</strong> {{ vehicule.statut }}</p>
          <p class="card-text"><strong>Année :</strong> {{ vehicule.annee }}</p>
          <p class="card-text"><strong>Capacité :</strong> {{ vehicule.capacite }} tonnes</p>
          <p class="card-text"><strong>Kilométrage :</strong> {{ vehicule.kilometrage }} km</p>
          <p class="card-text"><strong>Carburant :</strong> {{ vehicule.typeCarburant }}</p>
        </div>
        <div class="card-footer text-center">
          <button class="btn btn-outline-primary  mr-2" (click)="updateVehiculeForm(vehicule)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
          <button class="btn btn-outline-danger" (click)="deleteVehicule(vehicule.id)">
              <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
    
<div class="d-flex justify-content-center mt-3">
  <pagination-controls (pageChange)="page = $event"></pagination-controls>
</div>
</div>
