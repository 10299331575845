<div>
  <h1>Demande de Devis</h1>
    <form [formGroup]="devisForm" (ngSubmit)="onSubmit()">
      <!-- Ville Départ et Ville Arrivée -->
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="villeDepart" class="form-label">Ville Départ :</label>
          <select id="villeDepart" formControlName="villeDepart" class="form-select">
            <option value="" disabled selected>Code postal, Ville</option>
            <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
              {{ ville.codePostal }}, {{ ville.ville }} 
            </option>
          </select>
          <input type="radio" id="adressePro" name="adresse" value="professionnelle">
          <label for="adressePro" class="form-check-label">j'expédie depuis une adresse professionnelle.</label>
        </div>
        
        <div class="col-md-6">
          <label for="villeArrivee" class="form-label">Ville Arrivée :</label>
          <select id="villeArrivee" formControlName="villeArrivee" class="form-select">
            <option value="" disabled selected>Code postal, Ville</option>
            <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
              {{ ville.codePostal }}, {{ ville.ville }} 
            </option>
          </select>
          <input type="radio" id="adresseProLivraison" name="adresse" value="professionnelleLivraison">
          <label for="adresseProLivraison" class="form-check-label">je livre à une adresse professionnelle.</label>
        </div>
      </div>
      
      <!-- Envoi Section -->
      <h6>Envoi</h6>
      <hr>
      <div formArrayName="ligneEnvoi">
        <button type="button" class="btn-add" (click)="addLigneEnvoi()">
          <i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter Marchandise
        </button>
        <hr>
        <div class="table-responsive">
        <!-- Table to display the list -->
        <table class="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Description Marchandise</th>
              <th>Type d'Emballage</th>
              <th>Quantité</th>
              <th>Poids (kg)</th>
              <th>Longueur (cm)</th>
              <th>Largeur (cm)</th>
              <th>Hauteur (cm)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ligne of ligneEnvoi.controls; let i = index" [formGroupName]="i">
              <td>{{ i + 1 }}</td>
              <td><input formControlName="descriptionMarchandise" /></td>
              <td><input formControlName="typeEmbalage" /></td>
              <td><input formControlName="quantite" /></td>
              <td><input formControlName="poids" /></td>
              <td><input formControlName="longeur" /></td>
              <td><input formControlName="largeur" /></td>
              <td><input formControlName="hoteur" /></td>
              <td>
                <button type="button" (click)="removeLigneEnvoi(i)">Supprimer</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>  
      <!-- Client Information -->
      <h6>Client</h6>
      <hr>
    
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="nomClient" class="form-label">Nom du Client:</label>
          <input id="nomClient" formControlName="nomClient" type="text" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="telephoneClient" class="form-label">Téléphone du Client:</label>
          <input id="telephoneClient" formControlName="telephoneClient" type="text" class="form-control">
        </div>
      </div>
    
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="emailClient" class="form-label">Email du Client:</label>
          <input id="emailClient" formControlName="emailClient" type="email" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="dateLivraisonSouhaitee" class="form-label">Date de Livraison Souhaitée:</label>
          <input id="dateLivraisonSouhaitee" formControlName="dateLivraisonSouhaitee" type="date" class="form-control">
        </div>
      </div>
    
      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary" [disabled]="!devisForm.valid">Envoyer le Devis</button>
    </form>
  </div>
  