import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevisService } from 'src/app/Services/devis.service';
import { ReponseDevisService } from 'src/app/Services/reponse-devis.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-pdf-devis',
  templateUrl: './pdf-devis.component.html',
  styleUrls: ['./pdf-devis.component.css']
})
export class PdfDevisComponent implements OnInit {
  codeReponseDevis: string ='';
  reponseDevis: any = null;
  devis: any = null;
  currentDate: Date = new Date();
  
  constructor(private devisService: DevisService, private route: ActivatedRoute,
    private reponseDevisService: ReponseDevisService,
  ) { }

  ngOnInit(): void {
    this.codeReponseDevis = this.route.snapshot.paramMap.get('codeReponseDevis') || '';
    console.log('code Reponse Devis:', this.codeReponseDevis);

    this.devisService.getDevisByCodeReponseDevis(this.codeReponseDevis).subscribe(
      data => {
        this.devis = data;
        console.log("devis : ", this.devis);
       
        this.fetchReponseDevisByCode(this.codeReponseDevis);
      },
      error => {
        console.error('Erreur lors de la récupération du devis', error);
        this.devis = null;
   
      }
    );
  }

  fetchReponseDevisByCode(code: string) {
    this.reponseDevisService.getResponseByCodeDevis(code).subscribe(
      data => {
        this.reponseDevis = data;
        console.log("response devis : ", this.reponseDevis);
     
      },
      error => {
        console.error('Erreur lors de la récupération de la réponse de devis', error);
        this.reponseDevis = null;
        if (this.devis) {

        }
      }
    );
  }


  generatePDF() {
    const element = document.getElementById('invoice');
    if (element) {
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png'); // Génère l'image en base64
        const pdf = new jsPDF('p', 'mm', 'a4'); // Initialise le PDF en portrait et format A4
        
        // Calcul des dimensions pour s'adapter à la page A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Ajoute l'image au PDF
        // Génération du nom dynamique avec la date
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0]; // Format YYYY-MM-DD
        const fileName = `devis_${formattedDate}.pdf`; // Exemple : devis_2024-11-15.pdf
  
        pdf.save(fileName);
      });
    }
  }
  
}
