import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  // private apiUrl ='http://localhost:9067/api/contacts';
  
  private apiUrl = environment.APP_API_URL +"/api/contacts";
  constructor(private http: HttpClient) { }

  sendContactForm(contact: any): Observable<any> {
    return this.http.post(this.apiUrl, contact);
  }

  getContacts(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

}
