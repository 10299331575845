import { Component, OnInit } from '@angular/core';
import { MissionService } from 'src/app/Services/mission.service';
import { UserService } from 'src/app/Services/user-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-missions-client',
  templateUrl: './missions-client.component.html',
  styleUrls: ['./missions-client.component.css']
})
export class MissionsClientComponent implements OnInit {

  missions: any[] = [];
  searchText = '';

  currentUser: any; 

  selectedMission: any = null;
  page: number = 1;
  totalLength: number = 0;

  constructor(private missionService: MissionService, private userService: UserService) { }

  ngOnInit(): void {
    this.loadMissions();
    this.loadUser();
  }

  loadUser(): void {
    const userIdString = localStorage.getItem('idUser');
    

    if (userIdString) {
      this.userService.getUserById(userIdString).subscribe(data => {
        this.currentUser = data;
        this.loadMissions(); // Charger les missions après avoir récupéré l'utilisateur
      }, error => {
        console.error('Erreur lors de la récupération de l\'utilisateur', error);
      });
    }
  }

  loadMissions(): void {
    if (this.currentUser) {
      this.missionService.getMissions().subscribe(data => {
        this.missions = data.filter(mission => mission.devis.emailClient === this.currentUser.email);
        this.totalLength = this.missions.length;
      }, error => {
        console.error('Erreur lors de la récupération des missions', error);
      });
    }
  }

  viewMissionDetails(mission: any): void {
    this.selectedMission = mission;
  
    Swal.fire({
      title: `Détails de la mission`,
      html: `
        <div style="text-align: left;">
          <h4>Description</h4>
          <p>${mission.description}</p>
          <h4>Kilométrage</h4>
          <p>Début: ${mission.kilometrageDebut} km, Fin: ${mission.kilometrageArrive} km</p>
  
          <h4>Informations sur le véhicule</h4>
          <ul style="list-style: none; padding: 0;">
            <li><strong>Type :</strong> ${mission.vehicule.type}</li>
            <li><strong>Immatriculation :</strong> ${mission.vehicule.immatriculation}</li>
            <li><strong>Statut :</strong> ${mission.vehicule.statut}</li>
            <li><strong>Capacité :</strong> ${mission.vehicule.capacite} litres</li>
            <li><strong>Marque/Modèle :</strong> ${mission.vehicule.marque} - ${mission.vehicule.modele} (${mission.vehicule.annee})</li>
          </ul>
  
          <h4>Informations sur la marchandise</h4>
          <table style="width: 100%; text-align: left;">
            <thead>
              <tr>
                <th>Type d'emballage</th>
                <th>Description</th>
                <th>Quantité</th>
                <th>Poids</th>
                <th>Produit Dangereux</th>
              </tr>
            </thead>
            <tbody>
              ${mission.devis.ligneEnvoi.map((ligne: any) => `
                <tr>
                  <td>${ligne.typeEmbalage}</td>
                  <td>${ligne.descriptionMarchandise}</td>
                  <td>${ligne.quantite}</td>
                  <td>${ligne.poids} kg</td>
                  <td>${ligne.produitDangereux}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `,
      width: '900px',
      customClass: {
        popup: 'swal-popup-custom'
      },
      showCloseButton: true,
      confirmButtonText: 'Fermer'
    });
  }
  // Méthode pour fermer la vue des détails
  closeDetails(): void {
    this.selectedMission = null;
  }

}
