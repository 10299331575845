<div class="container">

 
    <div *ngIf="devis">
      <button (click)="generatePDF()" style="display: block; margin: 20px auto; padding: 8px 16px; background-color: #0056b3; color: #fff; border: none; font-size: 12px; cursor: pointer;">Télécharger en PDF</button>
      
      <div id="invoice" style="max-width: 800px; margin: 0 auto; padding: 40px; background-color: #fff; font-family: 'Arial', sans-serif; color: #333; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); line-height: 1.4; font-size: 12px;">
        <!-- Header -->
        <header style="display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 40px; border-bottom: 2px solid #0056b3; padding-bottom: 10px;">
          <div>
            <img src="/assets/logo_transp.png" alt="Logo MSS Consulting" style="width: 150px; height: auto; margin-bottom: 20px;">
            <h3 style="font-size: 24px; margin: 0; color: #0056b3;">Devis de Transport</h3>
            <p style="margin: 5px 0; font-size: 12px;">Code du devis : <strong>#{{ devis?.codeReponseDevis}}-{{ currentDate.getFullYear() }}</strong></p>
            <p style="font-size: 12px;">Date : <strong>{{ currentDate | date: 'd MMMM y' }}</strong></p>
          </div>
        
        </header>
      
        <section style="margin-bottom: 20px;">
          <h3 style="font-size: 16px; margin-bottom: 8px; color: #0056b3; border-bottom: 2px solid #ddd; padding-bottom: 8px;">Informations du client</h3>
          <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 0; row-gap: 0;">
            <!-- Première ligne -->
            <p style="font-size: 12px; margin: 3px;"><strong>Nom du client :</strong> {{ devis.nomClient }}</p>
            <p style="font-size: 12px; margin: 3px;"><strong>Email :</strong> sami.slim&#64;gmail.com</p>
            
            <!-- Deuxième ligne -->
            <p style="font-size: 12px; margin: 3px;"><strong>Adresse :</strong> Avenue 10 Décembre 1948, Ariana</p>
            <p style="font-size: 12px; margin: 3px;"><strong>Téléphone :</strong> +216 {{ devis.telephoneClient }}</p>
            
            <!-- Troisième ligne -->
            <p style="font-size: 12px; margin: 3px;"><strong>Date Livraison Souhaitée :</strong> {{ devis.dateLivraisonSouhaitee | date:'d MMMM yyyy à HH:mm' }}</p>
          </div>
        </section>
        
        
        <!-- Invoice Details -->
        <section>

          <section style="margin-bottom: 20px;">
            <h3 style="font-size: 16px; margin-bottom: 8px; color: #0056b3; border-bottom: 2px solid #ddd; padding-bottom: 8px;">Détails de la demande</h3>
            <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 0; row-gap: 0;">
              <!-- Première ligne -->
              <p style="font-size: 12px; margin: 3px;"><strong>Ville Départ: </strong>{{ devis.villeDepart }}</p>
              <p style="font-size: 12px; margin: 3px;"><strong>Ville Arrivée: </strong>{{ devis.villeArrivee }}</p>
              
              <!-- Deuxième ligne -->
              <p style="font-size: 12px; margin: 3px;"><strong>Date Demande: </strong>{{ devis.dateDemande | date: 'd MMMM y'  }}</p>
              <p style="font-size: 12px; margin: 3px;"><strong>Statut: </strong>{{ devis.statut }}</p>
            </div>
          </section>
          
          <table style="width: 100%; margin: 0 auto; border-collapse: collapse; margin-bottom: 40px; font-size: 12px;">
            <thead>
              <tr>
                <th style="border: 1px solid #ddd; padding: 4px; text-align: left;">Num</th>
                <th style="border: 1px solid #ddd; padding: 4px; text-align: left;">Description Marchandise</th>
                <th style="border: 1px solid #ddd; padding: 4px; text-align: left;">Type d'Emballage</th>
                <th style="border: 1px solid #ddd; padding: 4px; text-align: left;">Quantité</th>
                <th style="border: 1px solid #ddd; padding: 4px; text-align: left;">Poids</th>
                <th style="border: 1px solid #ddd; padding: 4px; text-align: left;">Taille</th>
                <th style="border: 1px solid #ddd; padding: 4px; text-align: left;">Dangereux</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ligne of devis.ligneEnvoi; let i = index">
                <td style="border: 1px solid #ddd; padding: 4px; text-align: left;">{{ i + 1 }}</td>
                <td style="border: 1px solid #ddd; padding: 4px; text-align: left;">{{ ligne.descriptionMarchandise }}</td>
                <td style="border: 1px solid #ddd; padding: 4px; text-align: left;">{{ ligne.typeEmbalage }}</td>
                <td style="border: 1px solid #ddd; padding: 4px; text-align: left;">{{ ligne.quantite }}</td>
                <td style="border: 1px solid #ddd; padding: 4px; text-align: left;">{{ ligne.poids }} kg</td>
                <td style="border: 1px solid #ddd; padding: 4px; text-align: left;">
                  <ul style="margin: 0; padding: 0; list-style: none;">
                    <li>Longueur: {{ ligne.longeur }} cm</li>
                    <li>Largeur: {{ ligne.largeur }} cm</li>
                    <li>Hauteur: {{ ligne.hoteur }} cm</li>
                  </ul>
                </td>
                <td style="border: 1px solid #ddd; padding: 4px; text-align: left;">{{ ligne.produitDangereux }}</td>
              </tr>
            </tbody>
          </table>
          
          
         
          <!-- Second Table: Summary of Costs -->
          <h3  *ngIf="reponseDevis" style="font-size: 16px; margin-bottom: 8px; color: #0056b3; border-bottom: 2px solid #ddd; padding-bottom: 8px;">Offre de prix</h3>
          <table style="width: 100%; margin: 0 auto; border-collapse: collapse; margin-bottom: 40px; font-size: 12px;">
            <thead>
              <tr>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Date Réponse</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Transporteur</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Email</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Numéro</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Adresse</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Ville</th>
                
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Prix en DT</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let resDevis of reponseDevis">
                <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ resDevis.dateReponse | date: 'd MMMM y' }}</td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: left;"> {{ resDevis.user.firstName }} {{ resDevis.user.lastName }}</td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ resDevis.user.email }}</td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ resDevis.user.phoneNumber }}</td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ resDevis.user.address }}</td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ resDevis.user.city }}</td>
           
                <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ resDevis.prix }}  DT</td>
              </tr>
            </tbody>
          </table>
          
          
          
        </section>
      <br>
      <br>
      <p style="margin: 10px 0; font-size: 12px; color: #3169b7;"><b>NB : Le chargement et le déchargement ne font pas partie de la prestation objet du devis</b></p>
      <br>
      <!-- Footer Amélioré pour Devis PDF -->
      <footer style="text-align: center; margin-top: 40px; border-top: 2px solid #ddd; padding-top: 15px; font-size: 12px; color: #555;">
        <p style="margin: 10px 0; font-size: 10px; color: #888;">Si vous avez des questions ou souhaitez plus d'informations, n'hésitez pas à nous contacter.</p>
     
        <p style="margin: 10px 0; font-size: 10px; color: #888;">MSS Consulting - Rue Apollo XI, Tunis, 1080 - Téléphone : +216 22 983 593 - Email : karim.gharbi&#64;mss.tn</p>
        
        
        <p style="margin: 10px 0; font-size: 10px; color: #aaa;">&copy; 2024 MSS Consulting - Tous droits réservés.</p>
      </footer>
      
      
      </div>
      
   
    </div>
  
  
