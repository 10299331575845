<div class="menu">

    <ul>
      <li *ngFor="let section of sections" (click)="openPopup(section.number)">
        <span class="menu-number">{{ section.number }}</span>
        <span class="menu-title">{{ section.title }}</span>
      </li>
    </ul>
  </div>
  
  <div class="content">
    <h1 class="page-title">
      Découvrez Comment Utiliser Notre Service de Transport
    </h1>
    <img src="assets/comment-ca-marche-img.png" alt="img" class="responsive-img" />


    <div class="flex-container">
        <div class="how-does-it-work-container">
            <div class="sections-container">
              <div *ngFor="let section of sections" class="section-card" (click)="openPopup(section.number)">
                <img [src]="section.imageUrl" [alt]="section.title" class="section-image">
                <h2 class="section-title">{{ section.number }}. {{ section.title }}</h2>
                <p class="section-description">{{ section.description }}</p>
           
              </div>
            </div>
          </div>
          
    </div>
  </div>
  