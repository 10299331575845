<div>
  <h1>Demande de Devis</h1>
  <form [formGroup]="devisForm" (ngSubmit)="onSubmit()">
    <div>
      <label for="villeDepart">Ville Départ :</label>
      <select id="villeDepart" formControlName="villeDepart" (change)="onVilleDepartChange($event)">
        <option value="" disabled selected>Code postal, Ville</option>
        <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
          {{ ville.codePostal }}, {{ ville.ville }}
        </option>
      </select>
    </div>

    <div>
      <label for="villeArrivee">Ville Arrivée :</label>
      <select id="villeArrivee" formControlName="villeArrivee">
        <option value="" disabled selected>Code postal, Ville</option>
        <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
          {{ ville.codePostal }}, {{ ville.ville }}
        </option>
      </select>
    </div>

    <h6>Envoi</h6>
    <div formArrayName="ligneEnvoi">
      <button type="button" (click)="addLigneEnvoi()">
        <i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter Marchandise
      </button>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Description Marchandise</th>
              <th>Type d'Emballage</th>
              <th>Quantité</th>
              <th>Poids (kg)</th>
              <th>Longueur (cm)</th>
              <th>Largeur (cm)</th>
              <th>Hauteur (cm)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ligne of ligneEnvoi.controls; let i = index" [formGroupName]="i">
              <td>{{ i + 1 }}</td>
              <td>{{ ligne.value.descriptionMarchandise }}</td>
              <td>{{ ligne.value.typeEmbalage }}</td>
              <td>{{ ligne.value.quantite }}</td>
              <td>{{ ligne.value.poids }}</td>
              <td>{{ ligne.value.longeur }}</td>
              <td>{{ ligne.value.largeur }}</td>
              <td>{{ ligne.value.hoteur }}</td>
              <td>
                <button type="button" (click)="removeLigneEnvoi(i)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h6>Client</h6>
    <div>
      <label for="nomClient">Nom du Client:</label>
      <input id="nomClient" formControlName="nomClient" type="text" placeholder="Nom du Client">
    </div>

    <div>
      <label for="telephoneClient">Téléphone du Client:</label>
      <input id="telephoneClient" formControlName="telephoneClient" type="text" placeholder="Téléphone du Client">
    </div>
    <div >
    <label for="emailClient" >Email du Client:</label>
    <input id="emailClient" formControlName="emailClient" type="email"  placeholder="Email du Client">
  </div>
  <div >
    <label for="dateLivraisonSouhaitee" >Date de livraison Souhaitée:</label>
    <input id="dateLivraisonSouhaitee" formControlName="dateLivraisonSouhaitee" type="date" >
  </div>
    <h6>Transporteurs</h6>
    <!-- Transporteurs -->
    <div formArrayName="idsTransporteur" class="mb-3">
      <div *ngIf="message" class="alert alert-warning" role="alert">
        {{ message }}
      </div>
      <div *ngFor="let transporteur of filteredTransporteurs; let i = index" class="form-check">
        <input 
          type="checkbox" 
          [formControlName]="i" 
          [value]="transporteur.id"
          class="form-check-input" 
          id="transporteur{{i}}">
        <label class="form-check-label" for="transporteur{{i}}">
          {{ transporteur.firstName }} {{ transporteur.lastName }}
        </label>
        <i class="fa fa-address-card-o ms-2" aria-hidden="true" (click)="viewTransporteur(transporteur)"></i>
      </div>
    </div>
      <!-- Pagination controls -->
<div class="pagination">
  <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1">Précédent</button>
  <span>Page {{ currentPage }} sur {{ totalPages }}</span>
  <button (click)="goToPage(currentPage + 1)" [disabled]="currentPage === totalPages">Suivant</button>
</div>


    <button type="submit" class="btn btn-primary">Envoyer</button>
  </form>
</div>
