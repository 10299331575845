<div *ngIf="user">
    <div class="user-profile">
      <div class="profile-img">
        <img *ngIf="!user.image" src="/assets/profile-avatar.png" alt="Profile Picture">
        <img *ngIf="user.image" [src]="'data:image/jpeg;base64,' + user.image.picByte"  alt="Profile Picture">
        <div class="online-status"></div>
        <button class="edit-button" (click)="showUpdatePopup()">
          <i class="fa fa-pencil"></i> <!-- Utilisation de FontAwesome pour l'icône -->
        </button>
      </div>
     
      <div class="profile-info">
        <p class="name">{{ user.firstName }} {{ user.lastName }}</p>
        <p class="role">{{ user.role }}</p>
        <div class="details">
          <p><strong>Email:</strong> {{ user.email }}</p>
          <p><strong>Téléphone:</strong> {{ user.phoneNumber }}</p>
          <p><strong>Adresse:</strong> {{ user.address }}</p>
          <p><strong>Ville:</strong> {{ user.city }}</p>
         
        </div>
      </div>

      <div class="profile-stats">
        <p><strong>Mes Chauffeurs </strong> <span class="badge rounded-pill">{{users.length}}</span></p>
        <p><strong>Mes Véhicules </strong> <span class="badge rounded-pill">{{ vehicules.length }}</span></p>
        <p><strong>Mes Missions </strong> <span class="badge rounded-pill">{{missions.length}}</span></p>
      </div>
    </div>
  
    <!-- Nouvelle section d'activités récentes -->
    <div class="recent-activities">
      <h3>Activités Récentes</h3>
      <ul>
        <li>Mission du 15 août: Livraison de colis</li>
        <li>Véhicule mis à jour le 10 août</li>
        <li>Chauffeur ajouté le 5 août</li>
      </ul>
    </div>
    
  
    <div class="loading" *ngIf="!user">
      <p>Chargement des données...</p>
    </div>
  </div>
  