import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/user-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  User: any;
  loading = false; 
  constructor(private router: Router, private userService: UserService) { }

  ngOnInit(): void {

  }

  logout(): void {

    localStorage.removeItem('idUser');
    localStorage.removeItem('token');

    this.router.navigate(['/login']);
  }
  isUserAuthenticated(): boolean {
    return !!window.localStorage.getItem('idUser');
  }

  getUserData(): void {
    const userId = localStorage.getItem('idUser');

    if (userId) {
      this.userService.getUserById(userId).subscribe(
        (data) => {
          this.User = data;
          console.log('Utilisateur chargé : ', this.User);
          this.loading = false; // Fin du chargement après la récupération des données
        },
        (error) => {
          console.error('Erreur lors de la récupération des données utilisateur', error);
          this.loading = false; // Arrêter le chargement même en cas d'erreur
        }
      );
    } else {
      this.loading = false; // Si aucun ID utilisateur, arrêter le chargement
    }
  }
  openSidebar() {
    const sidebar = document.getElementById('sidebar');
    if (sidebar) {
      sidebar.classList.add('open');
      this.getUserData();
      
    }
  }


  
  closeSidebar() {
    const sidebar = document.getElementById('sidebar');
    if (sidebar) {
      sidebar.classList.remove('open');
    }
  }
  
}
