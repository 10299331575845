<div class="user-table-container" *ngIf="users && users.length > 0">
  <button class="btn-export-excel" (click)="exportToExcel()">Télécharger Excel</button>
  <input type="text" [(ngModel)]="searchText" class="form-control search-input" placeholder="Rechercher..." />
  <table class="styled-table">
    <thead>
      <tr>
        <th>ID</th>
        <th>Nom</th>
        <th>Prénom</th>
        <th>Nom d'utilisateur</th>
        <th>Email</th>
        <th>Téléphone</th>
        <th>Rôle</th>
        <th>RNE</th>
        <th>Actions</th> <!-- Nouvelle colonne pour les actions -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users  | paginate: { itemsPerPage: 4, currentPage: page, totalItems: totalLength } | filter:searchText">
        <td>{{ user.id || '-' }}</td>
        <td>{{ user.firstName || '-' }}</td>
        <td>{{ user.lastName || '-' }}</td>
        <td>{{ user.userName || '-' }}</td>
        <td>{{ user.email || '-' }}</td>
        <td>{{ user.phoneNumber || '-' }}</td>
        <td>{{ user.role || '-' }}</td>
        <td>{{ user.rne || '-' }}</td>
        <td>
          <!-- Nouveau bouton Ajouter -->
          <button class="btn-add" (click)="addTransporteur(user)"> <span class="btn-text">Ajouter un Transporteur</span></button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-center mt-3">
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
  </div>
</div>

<div class="no-data-message" *ngIf="!users || users.length === 0">
  <p>Aucun utilisateur trouvé.</p>
</div>
