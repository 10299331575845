<div class="container mt-5 p-4 shadow-lg rounded" style="background-color: #f9f9f9;">
  <h2 class="text-center text-primary mb-4">Liste des Missions</h2>

  <!-- Display total missions count -->
  <div class="row mb-3">
    <div class="col-12">
      <p class="lead text-muted text-center"><strong>Total des missions :</strong> {{ missions.length }}</p>
    </div>
  </div>

  <!-- Search bar -->
  <div class="row mb-4 justify-content-center">
    <div class="col-12 col-md-8 col-lg-6">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Rechercher une mission..." [(ngModel)]="searchText">
        <div class="input-group-append">
          <span class="input-group-text bg-primary text-white"><i class="fas fa-search"></i></span>
        </div>
      </div>
    </div>
  </div>

  <!-- Missions table -->
  <div class="table-responsive">
    <table class="table table-bordered table-hover shadow-sm rounded">
      <thead class="thead-dark text-center">
        <tr>
          <th>#</th>
          <th>Date début</th>
          <th>Date fin</th>
          <th>Statut</th>
          <th>Véhicule</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mission of missions | filter: searchText; let i = index" class="text-center">
          <td>{{ i + 1 }}</td>
          <td>{{ mission.dateDebut | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ mission.dateFin | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td><span class="badge badge-info">{{ mission.statut }}</span></td>
          <td>{{ mission.vehicule.type }} - {{ mission.vehicule.immatriculation }}</td>
          <td>
            <button class="btn btn-info btn-sm" (click)="viewMissionDetails(mission)">
              <i class="fas fa-eye"></i> Détails
            </button>
          </td>
        </tr>
        <tr *ngIf="missions.length === 0">
          <td colspan="6" class="text-center text-muted">Aucune mission trouvée.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Pagination -->
  <div class="d-flex justify-content-center mt-4">
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
  </div>
</div>
