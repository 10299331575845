<app-navbar></app-navbar>
<div [ngClass]="{'authenticated': isUserAuthenticated()}">
  <app-sidebar *ngIf="isUserAuthenticated()" class="sidebar"></app-sidebar>
  <div class="main-content">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-footer *ngIf="!isUserAuthenticated()"></app-footer>






