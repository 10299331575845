import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {

//  private apiUrl = 'http://localhost:9067/api/user/register';
//  private apiUrl2 = 'http://localhost:9067/api/user';

  private apiUrl = environment.APP_API_URL +"/api/user/register";
  private apiUrl2 = environment.APP_API_URL +"/api/user";
  constructor(private http: HttpClient) { }


    registerUser(user: any): Observable<HttpResponse<any>> {
      return this.http.post(this.apiUrl, user, { observe: 'response', responseType: 'text' });
    }
  getUserById(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl2}/${userId}`);
  }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl2);
  }
  getChauffeurs(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl2).pipe(
      map(users => users.filter(user => user.role === 'Chauffeur'))
    );
  }
  
  SimpleUpdateUser(id: string, user: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl2}/${id}`, user);
  }

  updateUser(id: string, updatedUser: any): Observable<any> {
    const formData = new FormData();
    formData.append('user', JSON.stringify(updatedUser));
    if (updatedUser.image) {
      formData.append('image', updatedUser.image);
    }
    
    return this.http.put(`${this.apiUrl2}/${id}`, formData);
  }

}

