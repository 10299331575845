<div class="area"></div>
<nav class="main-menu">
    <div class="user-profile">
        <div class="profile-img">
            <img *ngIf="!User?.image" src="/assets/profile-avatar.png" alt="Profile Picture">
            <img *ngIf="User?.image" [src]="'data:image/jpeg;base64,' + User?.image.picByte" alt="Profile Picture">
            <div class="online-status"></div>
        </div>

        <div class="profile-info">
            <p class="name">{{User?.firstName}} {{User?.lastName}}</p>
            <p class="role">{{User?.role}}</p>
        </div>
    </div>

    <ul *ngIf="User?.role === 'Transporteur'">
        <li>
            <a routerLink="/home-page" routerLinkActive="active">
                <i class="fa fa-home fa-2x"></i>
                <span class="nav-text">Dashboard</span>
            </a>
        </li>
        <li>
            <a routerLink="/message-transporteur" routerLinkActive="active">
                <i class="fa fa-comments-o"></i>
                <span class="nav-text">
                    Messages 
                </span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="list-devis-transporteur" routerLinkActive="active">
                <i class="fa fa-file-text-o"></i>
                <span class="nav-text">Mes demandes devis</span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="confirm-devis" routerLinkActive="active">
                <i class="fa fa-file-text-o"></i>
                <span class="nav-text">Mes devis confirmés</span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="mission-transporteur" routerLinkActive="active">
                <i class="fa fa-truck"></i>
                <span class="nav-text">Mes missions</span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="statistique-mission" routerLinkActive="active">
                <i class="fa fa-bar-chart"></i>
                <span class="nav-text">Statistiques missions</span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="list-chauffeur" routerLinkActive="active">
                <i class="fa fa-users"></i>
                <span class="nav-text">Chauffeurs</span>
            </a>
        </li>
        <li>
            <a routerLink="vehicules" routerLinkActive="active">
                <i class="fa fa-bus"></i>
                <span class="nav-text">Véhicules</span>
            </a>
        </li>
        <li>
            <a routerLink="statistiques-vehicules" routerLinkActive="active">
                <i class="fa fa-bar-chart"></i>
                <span class="nav-text">Statistiques véhicules</span>
            </a>
        </li>
    </ul>

    <ul *ngIf="User?.role === 'Chauffeur'">
        <li>
            <a routerLink="/profil" routerLinkActive="active">
                <i class="fa fa-user-circle-o"></i>
                <span class="nav-text">Profil</span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="mission-chauffeur" routerLinkActive="active">
                <i class="fa fa-truck"></i>
                <span class="nav-text">Mes missions (chauffeur)</span>
            </a>
        </li>
        <li>
            <a routerLink="/message-chauffeur" routerLinkActive="active">
                <i class="fa fa-comments-o"></i>
                <span class="nav-text">
                    Messages <span class="badge badge-pill badge-danger">2</span>
                </span>
            </a>
        </li>
    </ul>
    <ul *ngIf="User?.role === 'Client'">
        <li>
            <a routerLink="/profil" routerLinkActive="active">
                <i class="fa fa-user-circle-o"></i>
                <span class="nav-text">Profil</span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="/missions-client" routerLinkActive="active">
                <i class="fa fa-truck"></i>
                <span class="nav-text">Mes comanndes</span>
            </a>
        </li>

    </ul>

    <ul *ngIf="User?.role === 'Admin'">
        <li>
            <a routerLink="/profil" routerLinkActive="active">
                <i class="fa fa-user-circle-o"></i>
                <span class="nav-text">Profil</span>
            </a>
        </li>
        <li class="has-subnav">
            <a routerLink="/transporteurs-admin" routerLinkActive="active">
                <i class="fa fa-users"></i>
                <span class="nav-text">Transporteus</span>
            </a>
        </li>

        <li class="has-subnav">
            <a routerLink="/contacts" routerLinkActive="active">
                <i class="fa fa-envelope"></i>
                <span class="nav-text">Contacts</span>
            </a>
        </li>
    </ul>

</nav>
