<div class="home-container">
    <!-- Hero Section -->
    <section class="hero-section text-center">
      <div class="container">
        <h6 class="display-4"> <b>Votre solution de transport de marchandises</b></h6>
        <p class="lead">Réservez, suivez, et gérez vos transports en toute simplicité.</p>
        <div class="form-container transparent-grey">

        <form [formGroup]="devisForm" (ngSubmit)="onSubmit()">
          <!-- Ville Départ et Ville Arrivée -->
          <div class="row">
            <div class="col-md-6">
              <label for="villeDepart" class="label">Ville Départ</label>
              <select id="villeDepart" formControlName="villeDepart" class="input-field" (change)="onVilleDepartChange($event)">
                <option value="" disabled selected>Code postal, Ville</option>
                <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
                  {{ ville.codePostal }}, {{ ville.ville }}
                </option>
              </select>
              <input type="radio" id="adressePro" name="adresse" value="professionnelle" class="radio-field">
              <label for="adressePro" class="radio-label">j'expédie depuis une adresse professionnelle.</label>
            </div>
            <div class="col-md-6">
              <label for="villeArrivee" class="label">Ville Arrivée</label>
              <select id="villeArrivee" formControlName="villeArrivee" class="input-field">
                <option value="" disabled selected>Code postal, Ville</option>
                <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
                  {{ ville.codePostal }}, {{ ville.ville }}
                </option>
              </select>
              <input type="radio" id="adresseProLivraison" name="adresse" value="professionnelleLivraison" class="radio-field">
              <label for="adresseProLivraison" class="radio-label">je livre à une adresse professionnelle.</label>
            </div>
          </div>
        
          <!-- Envoi Section -->
          <h6 class="section-header">Envoi</h6>
          <hr class="divider">
          <div formArrayName="ligneEnvoi">
            <button type="button" class="button" (click)="addLigneEnvoi()">Ajouter Marchandise</button>
            <hr class="divider">
            <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Description Marchandise</th>
                  <th>Type d'Emballage</th>
                  <th>Quantité</th>
                  <th>Poids (kg)</th>
                  <th>Longueur (cm)</th>
                  <th>Largeur (cm)</th>
                  <th>Hauteur (cm)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ligne of ligneEnvoi.controls; let i = index" [formGroupName]="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ ligne.value.descriptionMarchandise }}</td>
                  <td>{{ ligne.value.typeEmbalage }}</td>
                  <td>{{ ligne.value.quantite }}</td>
                  <td>{{ ligne.value.poids }}</td>
                  <td>{{ ligne.value.longeur }}</td>
                  <td>{{ ligne.value.largeur }}</td>
                  <td>{{ ligne.value.hoteur }}</td>
                  <td>
                    <button class="button button-danger" (click)="removeLigneEnvoi(i)">Supprimer</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        
          <!-- Client Information -->
          <h6 class="section-header">Client</h6>
          <hr class="divider">
          <div class="row">
            <div class="col-md-6">
              <input id="nomClient" formControlName="nomClient" type="text" class="input-field" placeholder="Nom Client">
            </div>
            <div class="col-md-6">
            
              <input id="telephoneClient" formControlName="telephoneClient" type="text" class="input-field" placeholder="Tél Client">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input id="emailClient" formControlName="emailClient" type="email" class="input-field" placeholder="Email Client">
            </div>
            <div class="col-md-6 mb-3">
              <input 
                id="dateLivraisonSouhaitee" 
                formControlName="dateLivraisonSouhaitee" 
                type="text" 
                class="form-control smaller-input" 
                placeholder="Livraison souhaitée" 
                onfocus="(this.type='date')" 
                onblur="(this.type='text')"
                style="font-size: 14px; color: #495057;"> <!-- Ajout d'un style pour le texte -->
            </div>
            
        

          </div>

            <!-- Transporteurs -->
        <div formArrayName="idsTransporteur" class="mb-3">
          <label for="transporteurs" class="form-label">Transporteurs</label>
          <div *ngIf="message" class="alert alert-warning" role="alert">
            {{ message }}
          </div>
          <div *ngFor="let transporteur of filteredTransporteurs; let i = index" class="form-check">
            <input 
              type="checkbox" 
              [formControlName]="i" 
              [value]="transporteur.id"
              class="form-check-input" 
              id="transporteur{{i}}">
            <label class="form-check-label" for="transporteur{{i}}">
              {{ transporteur.firstName }} {{ transporteur.lastName }}
            </label>
            <i class="fa fa-address-card-o ms-2" aria-hidden="true" (click)="viewTransporteur(transporteur)"></i>
          </div>
        </div>
        
          <!-- Pagination controls -->
          <div class="pagination">
            <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1" class="button">Précédent</button>
            <span class="pagination-text">Page {{ currentPage }} sur {{ totalPages }}</span>
            <button (click)="goToPage(currentPage + 1)" [disabled]="currentPage === totalPages" class="button">Suivant</button>
          </div>
        
          <button type="submit" class="button button-primary">Envoyer</button>
        </form>
      </div>
      </div>
    </section>
  
    <!-- About Section -->
    <section id="about" class="about-section py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h2>À propos de Mon Transporteur</h2>
            <p>Mon Transporteur est la solution idéale pour la gestion de vos transports de véhicules, offrant une interface intuitive, un suivi en temps réel et un support client 24/7. Notre objectif est de simplifier vos opérations de transport, tout en assurant une sécurité et une efficacité optimales.</p>
          </div>
          <div class="col-md-6">
            <img src="/assets/img_app.jpg" alt="À propos" class="custom-image" >
          </div>
        </div>
      </div>
    </section>
  
    <!-- Services Section -->
    <section id="services" class="services-section py-5 bg-light">
      <div class="container">
        <h2 class="text-center">Nos Services</h2>
        <div class="row mt-4">
          <div class="col-md-4">
            <div class="service-box text-center">
              <img src="/assets/img1.jpg" alt="Transport" class="img-fluid mb-3">
              <h3>Transport de marchandises</h3>
              <p>Assurez un transport sécurisé et rapide de vos marchandises avec nos services.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-box text-center">
              <img src="/assets/img2.jpg" alt="Tracking" class="img-fluid mb-3">
              <h3>Suivi en temps réel</h3>
              <p>Suivez l'état de votre transport à chaque étape du processus.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-box text-center">
              <img src="/assets/img3.png" alt="Support" class="img-fluid mb-3">
              <h3>Support 24/7</h3>
              <p>Profitez d'une assistance client disponible 24 heures sur 24, 7 jours sur 7.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <!-- Testimonials Section -->
    <section id="testimonials" class="testimonials-section py-5">
      <div class="container">
        <h2 class="text-center">Ce que disent nos clients</h2>
        <div class="row mt-4">
          <div class="col-md-4">
            <div class="testimonial-box">
              <p>"Mon Transporteur a simplifié nos opérations de transport. Le suivi en temps réel est un atout majeur pour notre entreprise."</p>
              <h5>- Sami Slim, Directeur Logistique</h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <p>"Je recommande vivement Mon Transporteur pour sa facilité d'utilisation et son service client exceptionnel."</p>
              <h5>- Ahmed Gharbi, Gestionnaire de flotte</h5>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <p>"Nous avons réduit nos coûts de transport grâce à l'efficacité de Mon Transporteur. Un incontournable pour toute entreprise."</p>
              <h5>- Mohamed Ben Ali, Responsable Transport</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  
  
  
  
    <!-- Transporters Section -->
    <section class="transporters-section text-center">
      <div class="container">
        <h4>Nos Transporteurs</h4>
        <div class="row">
          <div class="col-md-4">
            <div class="card mb-4">
              <img src="/assets/img4.jpg" class="card-img-top" alt="Transporteur 1">
              <div class="card-body">
                <h5 class="card-title">Transporteur Ali Ben Mahmoud</h5>
                <p class="card-text">Spécialisé dans le transport de marchandises de luxe avec une couverture européenne.</p>
                <a href="#" class="btn btn-primary">Voir le profil</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4">
              <img src="/assets/img5.jpg" class="card-img-top" alt="Transporteur 2">
              <div class="card-body">
                <h5 class="card-title">Transporteur Karim Gharbi</h5>
                <p class="card-text">Expert en transport de  marchandises utilitaires et de gros volumes.</p>
                <a href="#" class="btn btn-primary">Voir le profil</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4">
              <img src="/assets/img6.jpg" class="card-img-top" alt="Transporteur 3">
              <div class="card-body">
                <h5 class="card-title">Transporteur Sami Ben Ali</h5>
                <p class="card-text">Fournisseur de services de transport de  marchandises électriques et écologiques.</p>
                <a href="#" class="btn btn-primary">Voir le profil</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <!-- Customer Testimonials Section -->
    <section class="testimonials-section text-center py-5">
      <div class="container">
        <h2>Témoignages de Clients</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 1" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"Le service était impeccable, les colis sont arrivés à l'heure et en parfait état. Je recommande vivement !" </p>
              <h5 class="testimonial-name">Alice Dupont</h5>
              <p class="testimonial-role">Directrice Marketing</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 2" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"Une expérience exceptionnelle du début à la fin. Le suivi du transport est un vrai plus !" </p>
              <h5 class="testimonial-name">Jean Martin</h5>
              <p class="testimonial-role">Entrepreneur</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 3" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"J'ai pu transporter mon palette à travers toute la tunisie sans aucun souci. Merci à l'équipe !" </p>
              <h5 class="testimonial-name">Sophie Leclerc</h5>
              <p class="testimonial-role">Consultante</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- FAQ Section -->
    <section class="faq-section py-5">
      <div class="container">
        <h2 class="text-center">Questions Fréquemment Posées</h2>
        <div class="row">
          <div class="col-md-6">
            <div class="faq-item">
              <h5 class="faq-question">Comment puis-je réserver un transport de véhicule ?</h5>
              <p class="faq-answer">Vous pouvez réserver un transport de véhicule en utilisant notre application en ligne. Il vous suffit de créer un compte, de sélectionner le service souhaité, et de suivre les étapes indiquées.</p>
            </div>
            <div class="faq-item">
              <h5 class="faq-question">Quels types de véhicules pouvez-vous transporter ?</h5>
              <p class="faq-answer">Nous pouvons transporter une large gamme de véhicules, y compris des voitures, motos, camions légers, et même des véhicules spécialisés comme des camions-citernes.</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="faq-item">
              <h5 class="faq-question">Est-ce que mon véhicule est assuré pendant le transport ?</h5>
              <p class="faq-answer">Oui, tous les véhicules transportés par notre service sont couverts par une assurance complète, vous garantissant une tranquillité d'esprit totale.</p>
            </div>
            <div class="faq-item">
              <h5 class="faq-question">Comment puis-je suivre mon véhicule pendant le transport ?</h5>
              <p class="faq-answer">Vous pouvez suivre l'acheminement de votre véhicule en temps réel grâce à notre système de suivi intégré dans l'application.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="stats-section text-center bg-dark text-white py-5">
      <div class="container">
        <h2>Statistiques en Temps Réel</h2>
        <div class="row">
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">1,234</h3>
              <p class="stat-description">Véhicules en transit</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">567</h3>
              <p class="stat-description">Transporteurs actifs</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">89%</h3>
              <p class="stat-description">Clients satisfaits</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="stat-box">
              <h3 class="stat-number">123</h3>
              <p class="stat-description">Courses en cours</p>
            </div>
          </div>
        </div>
      </div>
    </section>