<div class="wrapper">
  <h1>Missions des Chauffeurs</h1>
  
  <div class="mission-container">
    <button class="btn btn-primary btn-add-mission" [routerLink]="['/create-mission-with-devis']">
      Ajouter Mission
    </button>
    
    <div class="mission-statistics">
      <h2 class="statistics-title">Statistiques des Missions</h2>
      <p class="statistics-total">Total des missions: <strong>{{ totalMissions }}</strong></p>
      
      <div class="statistics-grid">
        <div class="statistics-item">
          <span class="status-label">Prise en charge marchandise:</span> 
          <span class="badge rounded-pill">{{ totalMissionsByStatus['Prise en charge marchandise'] }}</span>
        </div>
        <div class="statistics-item">
          <span class="status-label">Marchandise livrée:</span> 
          <span class="badge rounded-pill">{{ totalMissionsByStatus['Marchandise livrée'] }}</span>
        </div>
        <div class="statistics-item">
          <span class="status-label">Revenu au parking:</span> 
          <span class="badge rounded-pill">{{ totalMissionsByStatus['Revenu au parking'] }}</span>
        </div>
        <div class="statistics-item">
          <span class="status-label">Mission démarrée:</span> 
          <span class="badge rounded-pill">{{ totalMissionsByStatus['Mission démarrée'] }}</span>
        </div>
        <div class="statistics-item">
          <span class="status-label">Mission créée:</span> 
          <span class="badge rounded-pill">{{ totalMissionsByStatus['Mission créée'] }}</span>
        </div>
        <div class="statistics-item">
          <span class="status-label">Retourner au parking </span>   
          <span class="badge rounded-pill">{{ totalMissionsByStatus['Retourner au parking'] }}</span>
       
        </div>
      </div>
    </div>
  </div>
  
  

  <div class="view-selector">
    <button class="btn view-btn" (click)="changeView('day')" [ngClass]="{'active': view === 'day'}">Jour</button>
    <button class="btn view-btn" (click)="changeView('week')" [ngClass]="{'active': view === 'week'}">Semaine</button>
    <button class="btn view-btn" (click)="changeView('month')" [ngClass]="{'active': view === 'month'}">Mois</button>
  </div>

  <!-- Tableau de bord -->
  <div class="dashboard">
    <table>
      <thead>
        <tr>
          <th>Chauffeur</th>
          <th *ngFor="let day of days">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let chauffeur of chauffeurs">
          <td class="chauffeur-name">{{ chauffeur.firstName }} {{ chauffeur.lastName }}

            <button class="btn btn-details" (click)="showChauffeurDetails(chauffeur)"><i class="fa fa-address-card-o" aria-hidden="true"></i></button>
          </td>
         
          <td *ngFor="let day of days" class="mission-cell">
            <div *ngFor="let task of getTasks(chauffeur.id, day)" class="mission-info">
              <p>{{ task.description }}</p>
              <p>{{ formatDateTime(task.dateDebut) }} - {{ formatDateTime(task.dateFin) }}</p>
              <p>Statut: {{ task.statut }}</p>

              <div class="task-buttons">
                <button class="btn btn-vehicule" (click)="showVehiculeDetails(task.vehicule)">
                  <i class="fa fa-truck"></i>
                </button>
                <button  class="btn btn-history" [routerLink]="['/mission-history', task.id]">
                  <i class="fa fa-history"></i>
                </button>
                <button class="btn btn-details" (click)="showMissionDetails(task)">
                  <i class="fa fa-file-text-o"></i>
                </button>
              </div>
              
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
