import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/Services/user-service.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { VilleService } from 'src/app/Services/ville.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  errorMessage: string | undefined;
  registerForm: FormGroup;
  confirmPasswordError: string = '';
  villesList: any[] = [];

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router, 
     private villeService: VilleService) {
    this.registerForm = this.fb.group({
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      rne: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(8)]],
      role: ['', Validators.required]
    }, { validators: this.passwordMatchValidator });
  }

  ngOnInit(): void {
    // Appel du service pour obtenir les villes
    this.villeService.getVilles().subscribe(data => {
      this.villesList = data;
    });
  }

  get userName() {
    return this.registerForm.get('userName');
  }

  get email() {
    return this.registerForm.get('email');
  }

  get password() {
    return this.registerForm.get('password');
  }

  get confirmPassword() {
    return this.registerForm.get('confirmPassword');
  }

    
  get firstName() {
    return this.registerForm.get('firstName');
  }
  
  get lastName() {
    return this.registerForm.get('lastName');
  }
  
  get phoneNumber() {
    return this.registerForm.get('phoneNumber');
  }

  get address() {
    return this.registerForm.get('address');
  }
  
  get city() {
    return this.registerForm.get('city');
  }


  get role() {
    return this.registerForm.get('role');
  }
  get rne() {
    return this.registerForm.get('rne');
  }

  
  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password && confirmPassword && confirmPassword.value !== password.value) {
      confirmPassword.setErrors({ mismatch: true });
    } else if (confirmPassword) {
      confirmPassword.setErrors(null);
    }
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      const confirmPasswordControl = this.confirmPassword;
      if (confirmPasswordControl) {
        if (confirmPasswordControl.hasError('mismatch')) {
          this.confirmPasswordError = 'Passwords do not match';
        } else if (confirmPasswordControl.hasError('required')) {
          this.confirmPasswordError = 'Confirm Password is required';
        }
      }
      return;
    }

    this.userService.registerUser(this.registerForm.value).subscribe(
      response => {
        console.log('response :', response.body);
        const responseBody = response.body?.trim(); // Assurez-vous de nettoyer la réponse de tout espace blanc éventuel
    console.log('response :', responseBody);
        if (responseBody === 'Email_already_exists') {
          this.errorMessage = 'Email already exists.';
          Swal.fire({
            icon: 'error',
            title: 'Email already exists.',
            text: 'Email already exists.'
          });
        } else if (responseBody === 'User registered successfully') {
          Swal.fire({
            icon: 'success',
            title: 'Registration Successful',
            text: 'User registered successfully. You can now login.',
            showConfirmButton: true
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl('/login');
            }
          });
        } else {
          console.error('Unexpected response from server:', responseBody);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Unexpected response from server. Please try again later.'
          });
        }
      },
      error => {
        console.error('Error registering user', error);
        this.errorMessage = 'Error registering user. Please try again.';
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.errorMessage
        });
      }
    );
  }    
}
