<div class="contact-container mt-5">
    <h2 class="text-center text-custom-color mb-4">Liste des Contacts</h2>
    <input type="text" [(ngModel)]="searchText" class="form-control search-input" placeholder="Rechercher..." />

    <div class="text-end mb-3">
        Total d'éléments : <span class="badge badge-pill badge-primary">{{ contacts.length }}</span> 
      </div>

    <div class="contact-cards-container">
      <div class="contact-card" *ngFor="let contact of contacts | paginate: { itemsPerPage: 4, currentPage: page, totalItems: totalLength }  | filter:searchText">
        <div class="contact-card-header">
          <h5 class="contact-name">{{ contact.name }}</h5>
          <span class="contact-id">ID: {{ contact.id }}</span>
        </div>
        <div class="contact-card-body">
          <p><strong>Email:</strong> {{ contact.email }}</p>
          <p><strong>Message:</strong> {{ contact.message }}</p>
        </div>
      </div>
    </div>

      
  <div class="d-flex justify-content-center mt-3">
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
  </div>
  </div>
  
  
  
