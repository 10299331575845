import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDevisService } from 'src/app/Services/confirm-devis.service';
import { DevisService } from 'src/app/Services/devis.service';
import { ReponseDevisService } from 'src/app/Services/reponse-devis.service';
import { UserService } from 'src/app/Services/user-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reponse-devis',
  templateUrl: './reponse-devis.component.html',
  styleUrls: ['./reponse-devis.component.css']
})
export class ReponseDevisComponent implements OnInit {

  reponseDevisForm: FormGroup;
  reponseDevis: any = null;
  devis: any = null;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    private reponseDevisService: ReponseDevisService,
    private devisService: DevisService, private confirmDevisService: ConfirmDevisService, private userService: UserService
  ) {
    this.reponseDevisForm = this.fb.group({
      codeReponseDevis: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // Initial setup if needed
  }

 
  onSubmit() {
    const code = this.reponseDevisForm.get('codeReponseDevis')!.value;
    this.devisService.getDevisByCodeReponseDevis(code).subscribe(
      data => {
        this.devis = data;
        console.log("devis : ", this.devis);
        this.errorMessage = null;
        this.fetchReponseDevisByCode(code);
      },
      error => {
        console.error('Erreur lors de la récupération du devis', error);
        this.devis = null;
        this.errorMessage = 'Devis non trouvé';
        this.reponseDevis = null;
      }
    );
  }

  fetchReponseDevisByCode(code: string) {
    this.reponseDevisService.getResponseByCodeDevis(code).subscribe(
      data => {
        this.reponseDevis = data;
        console.log("response devis : ", this.reponseDevis);
        this.errorMessage = null;
      },
      error => {
        console.error('Erreur lors de la récupération de la réponse de devis', error);
        this.reponseDevis = null;
        if (this.devis) {
          this.errorMessage = 'Pas de réponse pour le moment';
        }
      }
    );
  }
  confirm(resDevis: any) {
    const newObj = {
      devis: resDevis.devis,
      user: resDevis.user
    };

    // Utilisation de SweetAlert2 pour afficher une alerte modale
    Swal.fire({
      title: 'Confirmer',
      text: 'Êtes-vous sûr de vouloir envoyer cette confirmation de devis?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        // Appel du service pour créer la confirmation du devis
        this.confirmDevisService.createConfirmDevis(newObj).subscribe(
          (response: any) => {
            Swal.fire('Succès', 'Confirmation au devis envoyée avec succès!', 'success');
          },
          (error: any) => {
            Swal.fire('Erreur', 'Erreur lors de l\'enregistrement de la réponse au devis.', 'error');
          }
        );
      }
    });
  }

  openRegisterModal() {
    const emailDevis = this.devis?.emailClient; 
    const telephoneDevis = this.devis?.telephoneClient; 
    const firstNameDevis = this.devis?.nomClient;
  
    Swal.fire({
      title: 'Devenir un client',
      html:
        `<input type="text" id="userName" class="swal2-input" placeholder="Nom d'utilisateur">` +
        `<input type="password" id="password" class="swal2-input" placeholder="Mot de passe">`,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'S\'inscrire',
      cancelButtonText: 'Annuler',
      preConfirm: () => {
        const userName = (document.getElementById('userName') as HTMLInputElement).value;
        const password = (document.getElementById('password') as HTMLInputElement).value;
  
        if (!userName || !password) {
          Swal.showValidationMessage('Veuillez remplir tous les champs.');
          return null;
        }
  
        // Construire l'objet utilisateur avec les informations du devis et les valeurs saisies
        const user = {
          userName,
          email: emailDevis,
          password,
          phoneNumber: telephoneDevis,
          firstName: firstNameDevis,
          role: 'Client' // Rôle par défaut
        };
  
        return user; // Retourner l'objet utilisateur
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const user = result.value;
  
        // Appeler le service registerUser avec l'objet utilisateur
        this.userService.registerUser(user).subscribe({
          next: () => {
            Swal.fire('Succès', 'Inscription réussie!', 'success');
          },
          error: (error) => {
            Swal.fire('Erreur', 'Erreur lors de l\'inscription.', 'error');
          }
        });
      }
    });
  }
  
  
}


