import { Component, OnInit } from '@angular/core';
import { MissionService } from 'src/app/Services/mission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mission-chauffeur',
  templateUrl: './mission-chauffeur.component.html',
  styleUrls: ['./mission-chauffeur.component.css']
})
export class MissionChauffeurComponent implements OnInit {
  missions: any[] = [];
  searchText='';
  page: number = 1;
  totalLength: number = 0;

  statuts = ['Mission démarrée', 'Prise en charge marchandise', 'Marchandise livrée', 'Retourner au parking'];

  constructor(private missionService: MissionService) { }

  ngOnInit(): void {
    this.loadMissions();
  }

  loadMissions(): void {
    // Récupérer l'ID du Chauffeur depuis le localStorage
    const idChauffeur = Number(localStorage.getItem('idUser'));

    this.missionService.getMissions().subscribe(data => {
      this.missions = data.filter(mission => mission.idChauffeur === idChauffeur);
    }, error => {
      console.error('Erreur lors de la récupération des missions', error);
    });
  }

  sortByDateDebut(): void {
    this.missions.sort((a, b) => {
      const dateA = new Date(a.dateDebut);
      const dateB = new Date(b.dateDebut);
      return dateA.getTime() - dateB.getTime();
    });
  }

  sortByDateFin(): void {
    this.missions.sort((a, b) => {
      const dateA = new Date(a.dateFin);
      const dateB = new Date(b.dateFin);
      return dateA.getTime() - dateB.getTime();
    });
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'Prise en charge marchandise':
        return 'badge-primary'; 
      case 'Marchandise livrée':
        return 'badge-success'; 
      case 'Revenu au parking':
        return 'badge-warning'; 
      case 'Mission démarrée':
        return 'badge-info'; 
      case 'Mission créée':
        return 'badge-danger';
        case 'Retourner au parking':
          return 'badge-success'; 
      default:
        return 'badge-light'; // Couleur par défaut pour les statuts inconnus
    }
  }

openStatusEditPopup(mission: any) {
  let kilometrageDebut = mission.kilometrageDebut || 0;
  let kilometrageArrive = mission.kilometrageArrive || 0;

  Swal.fire({
    title: 'Modifier le statut de la mission',
    input: 'select',
    inputLabel: 'Choisissez un nouveau statut',
    inputOptions: this.getStatusOptions(),
    inputValue: mission.statut,
    showCancelButton: true,
    confirmButtonText: 'Enregistrer',
    cancelButtonText: 'Annuler',
    html: `
      <div style="margin-top: 10px;">
        <label id="labelKilometrageDebut" for="kilometrageDebut" style="display:none;">Kilométrage début</label>
        <input type="number" id="kilometrageDebut" class="swal2-input" value="${kilometrageDebut}" style="display:none;">
        <label id="labelKilometrageArrive" for="kilometrageArrive" style="display:none;">Kilométrage arrivée</label>
        <input type="number" id="kilometrageArrive" class="swal2-input" value="${kilometrageArrive}" style="display:none;">
      </div>
    `,
    didOpen: () => {
      const selectInput = Swal.getInput();
      const kmDebutInput = document.getElementById('kilometrageDebut') as HTMLInputElement;
      const kmArriveInput = document.getElementById('kilometrageArrive') as HTMLInputElement;
      const labelKmDebut = document.getElementById('labelKilometrageDebut') as HTMLLabelElement;
      const labelKmArrive = document.getElementById('labelKilometrageArrive') as HTMLLabelElement;

      
      if (selectInput) {
        selectInput.addEventListener('change', () => {
          const selectedStatus = (selectInput as HTMLInputElement).value;
          if (selectedStatus === 'Mission démarrée') {
            kmDebutInput.style.display = 'block';
            labelKmDebut.style.display = 'block';
            kmArriveInput.style.display = 'none';
            labelKmArrive.style.display = 'none';
          } else if (selectedStatus === 'Retourner au parking') {
            kmDebutInput.style.display = 'none';
            labelKmDebut.style.display = 'none';
            kmArriveInput.style.display = 'block';
            labelKmArrive.style.display = 'block';
          } else {
            kmDebutInput.style.display = 'none';
            labelKmDebut.style.display = 'none';
            kmArriveInput.style.display = 'none';
            labelKmArrive.style.display = 'none';
          }
        });

        // Affichage initial en fonction du statut actuel
        if (mission.statut === 'Mission démarrée') {
          kmDebutInput.style.display = 'block';
          labelKmDebut.style.display = 'block';
        } else if (mission.statut === 'Retourner au parking') {
          kmArriveInput.style.display = 'block';
          labelKmArrive.style.display = 'block';
        }
      }
    },
    preConfirm: () => {
      const newStatus = Swal.getInput()?.value;
      kilometrageDebut = (document.getElementById('kilometrageDebut') as HTMLInputElement).value;
      kilometrageArrive = (document.getElementById('kilometrageArrive') as HTMLInputElement).value;

      if (!newStatus) {
        Swal.showValidationMessage('Veuillez sélectionner un statut.');
      }

      return { newStatus, kilometrageDebut, kilometrageArrive };
    }
  }).then((result) => {
    if (result.isConfirmed) {
      const updatedMission = { 
        ...mission, 
        statut: result.value.newStatus, 
        kilometrageDebut: result.value.kilometrageDebut || mission.kilometrageDebut, 
        kilometrageArrive: result.value.kilometrageArrive || mission.kilometrageArrive 
      };

      this.missionService.updateMission(mission.id, updatedMission).subscribe({
        next: () => {
          mission.statut = result.value.newStatus;
          mission.kilometrageDebut = result.value.kilometrageDebut;
          mission.kilometrageArrive = result.value.kilometrageArrive;
          Swal.fire('Statut modifié!', '', 'success');
        },
        error: (err) => {
          Swal.fire('Erreur!', 'La mise à jour a échoué.', 'error');
        }
      });
    }
  });
}
  // Méthode pour générer les options de statut pour le menu déroulant
  getStatusOptions(): Record<string, string> {
    return this.statuts.reduce((options, status) => {
      options[status] = status;
      return options;
    }, {} as Record<string, string>);
  }


}
