import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { UserService } from 'src/app/Services/user-service.service';
import { VilleService } from 'src/app/Services/ville.service';
import { DevisService } from 'src/app/Services/devis.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-devis-form-client-trasporteur-selected',
  templateUrl: './devis-form-client-trasporteur-selected.component.html',
  styleUrls: ['./devis-form-client-trasporteur-selected.component.css']
})
export class DevisFormClientTrasporteurSelectedComponent implements OnInit {

  idTransporteur: number | undefined;
  devisForm: FormGroup;
  villesList: { codePostal: string, ville: string }[] = []; // Remplacez ceci par la liste réelle des villes

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService, private villeService: VilleService, private devisService: DevisService
  ) {
    this.devisForm = this.fb.group({
      villeDepart: [''],
      villeArrivee: [''],
      ligneEnvoi: this.fb.array([]),
      nomClient: [''],
      telephoneClient: [''],
      emailClient: [''],
      dateLivraisonSouhaitee: [''],
      idsTransporteur: this.fb.array([]) // Éliminer les transporteurs de la sélection
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idTransporteur = +params['id']; // Assurez-vous que l'ID est un nombre
      console.log('idTransporteur: ', this.idTransporteur);
      if (this.idTransporteur) {
        this.loadVilles(); // Chargez les villes si nécessaire
        this.loadTransporteur(); // Charger les informations du transporteur si nécessaire
      }
    });
  }

  get ligneEnvoi(): FormArray {
    return this.devisForm.get('ligneEnvoi') as FormArray;
  }

  loadVilles(): void {
    this.villeService.getVilles().subscribe(data => {
      this.villesList = data;
    });
  }

  loadTransporteur(): void {
   
    // this.transporteurService.getTransporteurById(this.idTransporteur.toString()).subscribe(data => {
    //   // Utilisez les données du transporteur comme nécessaire
    // });
  }

  onSubmit(): void {
    if (this.devisForm.valid) {
      const formData = this.devisForm.value;
      formData.idsTransporteur = [this.idTransporteur]; 
      // Ajoutez l'ID du transporteur au formulaire
      
      // Envoyer les données du formulaire au serveur
      this.sendDevis(formData);
    }
  }

  generateCode(): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
    for (let i = 0; i < 8; i++) {
      code += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    console.log('Generated Code:', code);
    return code;
  }

  sendDevis(data: any): void { 
    const codeReponseDevis = this.generateCode();
    data.codeReponseDevis = codeReponseDevis;

    console.log('Envoi du devis:', data);
    this.devisService.createDevis(data).subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Devis envoyé avec succès',
          html: `Voici votre code pour suivre la réponse : <a href="/reponse-devis/${codeReponseDevis}">${codeReponseDevis}</a>`
        }).then(() => {
          this.router.navigateByUrl(`reponse-devis/${codeReponseDevis}`);
        });
      },
      error => {
        console.error('Erreur lors de la création du devis:', error);
        Swal.fire('Erreur', 'Erreur lors de la création du devis. Veuillez vérifier les détails et réessayer.', 'error');
      }
    );
  
  }

  addLigneEnvoi(): void {
    this.ligneEnvoi.push(this.fb.group({
      descriptionMarchandise: [''],
      typeEmbalage: [''],
      quantite: [''],
      poids: [''],
      longeur: [''],
      largeur: [''],
      hoteur: ['']
    }));
  }

  removeLigneEnvoi(index: number): void {
    this.ligneEnvoi.removeAt(index);
  }
}
