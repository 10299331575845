<div class="home-container">

<!--modification sur le hero -->
<section class="hero" *ngIf="!showPreloader">
  <div class="container-hero">
    <div class="partie1">
      <div class="cadre">
        <h4 class="title-hero" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          Votre solution de transport de</h4>
        <h5 class="sous-title-hero" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">marchandises<span class="sous-title"> Mon transporter</span></h5>
        <p class="desc" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam quam dicta sit ut corrupti magni, optio.</p>
        <button (click)="onClickScroll('form')" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="800">Commencer</button>

    </div>
    </div>

    <div class="partie2">
      <div class="camion" data-aos="slide-left" data-aos-duration="1200" data-aos-delay="200"> </div>
      <div class="slider-container" ></div>
    </div>
  </div>
</section>

<div *ngIf="showPreloader" class="preloader">
  <div class="block-preloader">
    <img src="/assets/preloader.gif" alt="Loading..." />
    <h4 id="waveText">
      <span>M</span><span>o</span><span>n</span>
      <span> </span><span>T</span><span>r</span><span>a</span><span>n</span><span>s</span>
      <span>p</span><span>o</span><span>r</span><span>t</span><span>e</span><span>u</span><span>r</span>
      <span> </span><span>...</span>
    </h4>
    
  </div>
</div>


 

    <!-- About Section -->
    <section id="about" class="about-section" >
        <div class="block2" data-aos="fade-right" data-aos-duration="800" data-aos-delay="300">
            <img src="/assets/background/camion2.png" alt="À propos" class="custom-image" >
        </div>
        <div class="block1" data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
          <div class="groupe">
            <div class="bar-about">
            <div class="sous-tite-about">À propos de Mon Transporteur</div>
            </div>
            <h2 class="titre-about">Vous recherchez les meilleurs services de transport ?</h2>
            <span class="desc-about">Mon Transporteur se positionne comme la solution incontournable pour la gestion de vos transports 
              de véhicules. Grâce à une interface conviviale, un suivi en temps réel et un service client disponible 24h/24 et 7j/7, nous
              simplifions vos opérations de transport. Notre priorité est d’assurer une sécurité maximale et une efficacité optimale, afin 
              de répondre à vos besoins avec excellence.</span>
              <div class="image-about"></div>
          </div>
          <div class="bg"></div>
          <div class="bg2"></div>
        </div>

    </section>

 <!-- Testimonials Section -->
 <section  class="testimonials">
  <div class="container">
    <div class="title" data-aos="fade-up"  data-aos-duration="800" data-aos-delay="200">
      <div class="bar-about">
        <div class="sous-titre-testimonials">
          <i class="fas fa-star filled"></i>&ensp;Témoignages de clients&ensp;<i class="fas fa-star filled"></i>
        </div>
      </div>
    <!--<img src="/assets/background/sub-before.png" alt="test"><br>-->
    <h2 class="section-title"><i class="fas fa-comment-dots"></i>&ensp;&ensp;
      De vrais avis de clients ! &ensp;&ensp;<i class="fas fa-comment-dots"></i>
    </h2>
  </div><br>
    <div class="Card-liste" data-aos="fade-up"  data-aos-duration="1200" data-aos-delay="200">

        <div class="testimonial-box">
          <div class="user">
            <img src="/assets/user.jpg" alt="Transport" class="img-user">
            <div class="testimonials-poste">
              <h3 class="testimonials-nom">Sami Slim</h3>
              <p class="testimonials-sous-nom">Directeur Logistique</p>
            </div>
          </div>
          <p class="testimonial-description">Mon Transporteur a simplifié nos opérations de transport. Le suivi en temps réel est un atout majeur pour notre entreprise.</p>
          <div class="footer">
            <div class="rating">
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="testimonial-footer-img"></div>
          </div>
        </div>


        <div class="testimonial-box">
          <div class="user">
            <img src="/assets/user2.jpg" alt="Transport" class="img-user">
            <div class="testimonials-poste">
              <h3 class="testimonials-nom">Ahmed Gharbi</h3>
              <p class="testimonials-sous-nom">Gestionnaire de flotte</p>
            </div>
          </div>
          <p class="testimonial-description">Je recommande vivement Mon Transporteur pour sa facilité d'utilisation et son service client exceptionnel.</p>
          <div class="footer">
            <div class="rating">
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="testimonial-footer-img"></div>
          </div>
        </div>

        <div class="testimonial-box">
          <div class="user">
            <img src="/assets/user3.jpg" alt="Transport" class="img-user">
            <div class="testimonials-poste">
              <h3 class="testimonials-nom">Mohamed Ben Ali</h3>
              <p class="testimonials-sous-nom">Responsable Transport</p>
            </div>
          </div>
          <p class="testimonial-description">Nous avons réduit nos coûts de transport grâce à l'efficacité de Mon Transporteur. Un incontournable pour toute entreprise.</p>
          <div class="footer">
            <div class="rating">
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="testimonial-footer-img"></div>
          </div>
        </div>


    </div>
  </div>
</section>
<!-- formulaire Section -->
<section class="formulaire-section"data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" id="form">
<div class="titre-form" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
  <h4 class="titre-form-h4">Votre solution de transport de marchandises</h4>
  <div class="desc-form">Réservez, suivez, et gérez vos transports en toute simplicité.</div>
</div>
<div class="block-form">
<div class="form" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" >
  
  <form [formGroup]="devisForm" (ngSubmit)="onSubmit()">
    <!-- Ville Départ et Ville Arrivée -->

    <div class="row">
      <div class="col-md-6">
        <label for="villeDepart" class="titre-header">
          <i class="fas fa-city"></i>&ensp;Ville Départ</label>
        <select id="villeDepart" formControlName="villeDepart" class="input-field" (change)="onVilleDepartChange($event)">
          <option value="" disabled selected>Code postal, Ville</option>
          <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
            {{ ville.codePostal }}, {{ ville.ville }}
          </option>
        </select>
        <div class="radio">
          <input type="radio" id="adressePro" name="adresse" value="professionnelle" class="radio-field">
          <label for="adressePro" class="radio-label">j'expédie depuis une adresse professionnelle.</label>
        </div>
      </div>
      <div class="col-md-6">
        <label for="villeArrivee" class="titre-header">
          <i class="fas fa-city"></i>&ensp;Ville Arrivée</label>
        <select id="villeArrivee" formControlName="villeArrivee" class="input-field">
          <option value="" disabled selected>Code postal, Ville</option>
          <option *ngFor="let ville of villesList" [value]="ville.codePostal + ', ' + ville.ville">
            {{ ville.codePostal }}, {{ ville.ville }}
          </option>
        </select>
        <div class="radio">
          <input type="radio" id="adresseProLivraison" name="adresse" value="professionnelleLivraison" class="radio-field">
          <label for="adresseProLivraison" class="radio-label">je livre à une adresse professionnelle.</label>
        </div>
      </div>
    </div>
  
    <!-- Envoi Section -->

    <hr class="divider">
    <div formArrayName="ligneEnvoi">
      <button type="button" class="button" (click)="addLigneEnvoi()">
        <i class="fas fa-plus"></i>&ensp;Ajouter Marchandise</button>
      <hr class="divider">
      <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Description Marchandise</th>
            <th>Type d'Emballage</th>
            <th>Quantité</th>
            <th>Poids (kg)</th>
            <th>Longueur (cm)</th>
            <th>Largeur (cm)</th>
            <th>Hauteur (cm)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ligne of ligneEnvoi.controls; let i = index" [formGroupName]="i">
            <td>{{ i + 1 }}</td>
            <td>{{ ligne.value.descriptionMarchandise }}</td>
            <td>{{ ligne.value.typeEmbalage }}</td>
            <td>{{ ligne.value.quantite }}</td>
            <td>{{ ligne.value.poids }}</td>
            <td>{{ ligne.value.longeur }}</td>
            <td>{{ ligne.value.largeur }}</td>
            <td>{{ ligne.value.hoteur }}</td>
            <td>
              <button class="button button-danger" (click)="removeLigneEnvoi(i)">Supprimer</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <!-- Client Information -->

    <h6 class="titre-header"><i class="fas fa-user"></i>&ensp;Client</h6>
    <hr class="divider">
    <div class="row">
      <div class="col-md-6">
        <input id="nomClient" formControlName="nomClient" type="text" class="input-field" placeholder="Nom Client">
      </div>
      <div class="col-md-6">
      
        <input id="telephoneClient" formControlName="telephoneClient" type="text" class="input-field" placeholder="Tél Client">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <input id="emailClient" formControlName="emailClient" type="email" class="input-field" placeholder="Email Client">
      </div>
      <div class="col-md-6 mb-3">
        <label
        for="dateCreationOrg"
        class="floating-label-text"
        *ngIf="!isDateSelected"
        
      >
        Date Création 
      </label>
        <input 
        
        ngDefaultControl
        (change)="onDateChange($event)"

        id="dateLivraisonSouhaitee" 
        formControlName="dateLivraisonSouhaitee" 
        type="date" 
        class="form-control smaller-input">
      </div>
      
  

    </div>

      <!-- Transporteurs -->

  <div formArrayName="idsTransporteur" class="mb-3">
    <label for="transporteurs" class="titre-header"><i class="fas fa-user-tie"></i>&ensp;Transporteurs</label>
    <div *ngIf="message" class="alert alert-warning" role="alert">
      {{ message }}
    </div>
    <div *ngFor="let transporteur of filteredTransporteurs; let i = index" class="form-check">
      <input 
        type="checkbox" 
        [formControlName]="i" 
        [value]="transporteur.id"
        class="form-check-input" 
        id="transporteur{{i}}">
      <label class="form-check-label" for="transporteur{{i}}">
        {{ transporteur.firstName }} {{ transporteur.lastName }}
      </label>
      <i class="fa fa-address-card-o ms-2" aria-hidden="true" (click)="viewTransporteur(transporteur)"></i>
    </div>
  </div>
  
    <!-- Pagination controls -->
 <div class="footer-form">
    <div class="pagination">
      <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1" class="button">
        <i class="fas fa-chevron-left"></i>&ensp;Précédent</button>
      <span class="pagination-text">Page {{ currentPage }} sur {{ totalPages }}</span>
      <button (click)="goToPage(currentPage + 1)" [disabled]="currentPage === totalPages" class="button">
        Suivant&ensp;<i class="fas fa-chevron-right"></i></button>
    </div>
  
    <button type="submit" class="button button-primary">
      Envoyer&ensp;<i class="fas fa-paper-plane"></i></button>
  </div>
  </form>
</div>
</div>
</section>
  
    <!-- Services Section -->
    <section class="services-section">
      <div class="container">
        <div class="title" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
          <div class="sous-titre">
            <div class="sous-titre-testimonials">
              <i class="fas fa-box"></i>&ensp;Nos Services&ensp;<i class="fas fa-box"></i>
            </div>
          </div>
         
            <h2 class="section-title">Solutions complètes pour le transport</h2>
        </div>
<br>
        <div class="Card-liste" data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
            <div class="service-box text-center">
              <div class="image">
                 <img src="/assets/img1.png" alt="Transport" class="img-bg">
               </div>
                 <h3 class="card-title">Transport de marchandises</h3>
                 <p class="description-content">Assurez un transport sécurisé et rapide de vos marchandises avec nos services.</p>
            </div>

            <div class="service-box text-center">
              <div class="image">
                <img src="/assets/img4.avif" alt="Tracking" class="img-bg">
              </div>
                <h3 class="card-title">Suivi en temps réel</h3>
                <p class="description-content">Suivez l'état de votre transport à chaque étape du processus.</p>
            </div>

            <div class="service-box text-center">
              <div class="image">
              <img src="/assets/img3.jpg" alt="Support" class="img-bg">
              </div>
              <h3 class="card-title">Support 24/7</h3>
              <p class="description-content">Profitez d'une assistance client disponible 24 heures sur 24, 7 jours sur 7.</p>
            </div>

        </div>
      </div>
    </section>
  
   
  
  
  <!--
  <section class="bar3">
    <ul class="liste-item">
      <li>Completed Delivery</li>
      <li>Satisfied Clients</li>
      <li>Awards Winner</li>
      <li>Team Members</li>
    </ul>
  </section>-->



    <!-- Transporters team Section -->
  
    <section class="transporters-section">
      <div class="container">
        <h2 class="section-title" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
          Nos transporteurs spécialisés
          </h2><br><br><br>
        <div class="Card-liste2" data-aos="fade-up" data-aos-duration="600" data-aos-delay="800">


            <div class="card-transporters">
              <img src="/assets/img4.jpg" class="card-img-transporters" alt="Transporteur 1">
              <div class="card-body">
                <h5 class="card-title">Ali Ben Mahmoud</h5>
                <p class="card-text">Spécialisé dans le transport de marchandises de luxe .</p>
              </div>
            </div>

 
            <div class="card-transporters">
              <img src="/assets/img5.jpg" class="card-img-transporters" alt="Transporteur 2">
              <div class="card-body">
                <h5 class="card-title">Karim Gharbi</h5>
                <p class="card-text">Expert en transport de  marchandises utilitaires et de gros volumes.</p>
              </div>
            </div>

            <div class="card-transporters">
              <img src="/assets/img6.jpg" class="card-img-transporters" alt="Transporteur 3">
              <div class="card-body">
                <h5 class="card-title">Sami Ben Ali</h5>
                <p class="card-text">Fournisseur de services de transport de  marchandises électriques et écologiques.</p>
              </div>
            </div>
        </div>
      
        <div class="bar" ></div>
      </div>
    </section>
  
    <!-- Customer Testimonials Section -->
      <!--
    <section class="testimonials-section2">
      <div class="container">
       
        <h2>Témoignages de Clients</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 1" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"Le service était impeccable, les colis sont arrivés à l'heure et en parfait état. Je recommande vivement !" </p>
              <h5 class="testimonial-name">Alice Dupont</h5>
              <p class="testimonial-role">Directrice Marketing</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 2" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"Une expérience exceptionnelle du début à la fin. Le suivi du transport est un vrai plus !" </p>
              <h5 class="testimonial-name">Jean Martin</h5>
              <p class="testimonial-role">Entrepreneur</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-box">
              <img src="/assets/profile-avatar.png" alt="Client 3" class="testimonial-img rounded-circle">
              <p class="testimonial-text">"J'ai pu transporter mon palette à travers toute la tunisie sans aucun souci. Merci à l'équipe !" </p>
              <h5 class="testimonial-name">Sophie Leclerc</h5>
              <p class="testimonial-role">Consultante</p>
            </div>
          </div>
        </div>
      </div>
    </section>-->
    <!-- FAQ Section -->
    <section class="faq-section py-5">
      <div class="container">
         <h2 class="section-title" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">Questions Fréquemment Posées ?</h2>

      <div class="block_question"data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">

        <div class="container_question" >
            <div class="faq-item" *ngFor="let faq of faqsLeft">

              <div class="header_question">
                  <button (click)="toggleAnswer(faq)">
                    {{ faq.showAnswer ? '-' : '+' }}
                  </button>
                  <h5 class="faq-question">
                  {{ faq.question }}
                  </h5>
              </div>

              <p *ngIf="faq.showAnswer" class="faq-answer">{{ faq.answer }}</p>
            
            </div>
            <div class="faq-item" *ngFor="let faq of faqsRight">

              <div class="header_question">
                  <button (click)="toggleAnswer(faq)">
                    {{ faq.showAnswer ? '-' : '+' }}
                  </button>
                  <h5 class="faq-question">
                  {{ faq.question }}
                  </h5>
              </div>

                  <p *ngIf="faq.showAnswer" class="faq-answer">{{ faq.answer }}</p>

                </div>

            </div>
        
          <div class="image-question">
            <img src="/assets/question.png" alt="question">
          </div>

      </div>

      </div>
    </section>
    