<div class="container">
  <h1 style="margin-top:2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Réponse Devis</h1>
  <p style="color: gray;  text-align: center;">
    Entrez le code de réponse du devis reçu par e-mail lors de la demande.
  </p>
  <hr style="width: 20rem;  margin: 15px auto;">

  <div *ngIf="!devis" class="mt-3 devis-container">
    <div class="card custom-card">
      <div class="card-body">
  <form [formGroup]="reponseDevisForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="codeReponseDevis">Code Réponse Devis:</label>
      <input type="text" id="codeReponseDevis" formControlName="codeReponseDevis" class="form-control" required>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>

  <div *ngIf="errorMessage" class="alert alert-danger mt-3">
    {{ errorMessage }}
  </div>
</div>
</div>
</div>
  <div *ngIf="devis">
    <button (click)="generatePDF()" style="display: block; margin: 20px auto; padding: 8px 16px; background-color: #0056b3; color: #fff; border: none; font-size: 12px; cursor: pointer;">Télécharger en PDF</button>
    
    <div id="invoice" style="max-width: 800px; margin: 0 auto; padding: 40px; background-color: #fff; font-family: 'Arial', sans-serif; color: #333; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); line-height: 1.4; font-size: 12px;">
      <!-- Header -->
      <header style="display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 40px; border-bottom: 2px solid #0056b3; padding-bottom: 10px;">
        <div>
          <img src="/assets/logo_transp.png" alt="Logo MSS Consulting" style="width: 150px; height: auto; margin-bottom: 20px;">
          <h3 style="font-size: 24px; margin: 0; color: #0056b3;">Devis de Transport de
            <br> Marchandises</h3>
          <p style="margin: 5px 0; font-size: 12px;">Numéro du devis : <strong>#00123</strong></p>
          <p style="font-size: 12px;">Date : <strong>19 septembre 2024</strong></p>
        </div>
        <div style="text-align: right;">
          <h2 style="font-size: 18px; margin: 0; color: #0056b3;">MSS Consulting</h2>
          <p style="margin: 8px 0; font-size: 12px;">Rue Apollo XI<br>Tunis 1080</p>
          <p style="font-size: 12px;">Téléphone : +216 22 983 593<br>Email : karim.gharbi&#64;mss.tn</p>
        </div>
      </header>
    
      <!-- Client Information -->
      <section style="margin-bottom: 20px;">
        <h3 style="font-size: 16px; margin-bottom: 8px; color: #0056b3; border-bottom: 2px solid #ddd; padding-bottom: 8px;">Informations du client</h3>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Nom du client :</strong>  {{ devis.nomClient }} </p>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Adresse :</strong> Avenue 10 Décembre 1948, Ariana</p>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Email :</strong> sami.slim&#64;gmail.com</p>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Téléphone :</strong> +216  {{ devis.telephoneClient }}</p>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Date Livraison Souhaitée :</strong>  {{ devis.dateLivraisonSouhaitee | date:'d MMMM yyyy à HH:mm' }}
        </p>
      </section>
    
      <!-- Invoice Details -->
      <section>
        <h3 style="font-size: 16px; margin-bottom: 8px; color: #0056b3; border-bottom: 2px solid #ddd; padding-bottom: 8px;">Informations relatives à la demande de devis</h3>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Ville Départ: </strong>{{ devis.villeDepart }}</p>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Ville Arrivée: </strong> {{ devis.villeArrivee }}</p>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Date Demande: </strong>  {{ devis.dateDemande }}</p>
        <p style="font-size: 12px; margin: 10px 0;"><strong>Statut: </strong> {{ devis.statut }}</p>
        <table style="width: 100%; margin: 0 auto; border-collapse: collapse; margin-bottom: 40px; font-size: 12px;">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Num</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Description Marchandise</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Type d'Emballage</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Quantité</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Poids</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Taille</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Dangereux</th>
            </tr>
          </thead>
          <tbody>
           
            <tr *ngFor="let ligne of devis.ligneEnvoi; let i = index">
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;"> {{ i + 1 }}</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ ligne.descriptionMarchandise }}</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;"> {{ ligne.typeEmbalage }}</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ ligne.quantite }}</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">{{ ligne.poids }} kg</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">
                <ul>
                  <li>Longueur: {{ ligne.longeur }} cm</li>
                  <li>Largeur: {{ ligne.largeur}} cm</li>
                  <li>Hauteur: {{ ligne.hoteur}} cm</li>
                </ul>
              </td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;"> {{ ligne.produitDangereux}}</td>
            </tr>
          </tbody>
        </table>
        
    
        <!-- Second Table: Summary of Costs -->
        <h3 style="font-size: 16px; margin-bottom: 8px; color: #0056b3; border-bottom: 2px solid #ddd; padding-bottom: 8px;">Réponse à votre demande de devis</h3>
        <table style="width: 100%; margin: 0 auto; border-collapse: collapse; margin-bottom: 40px; font-size: 12px;">
          <thead>
            <tr>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Date Réponse</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Transporteur</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Email</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Adresse</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Ville</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Prix</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">Sep 20, 2024, 1:35:43 PM</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">Samah Gharbi</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">sameh&#64;gmail.com</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">10 Rue du Centre, Ariana</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">1000, Tunis</td>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">100 DT</td>
            </tr>
          </tbody>
        </table>
        
        
        
      </section>
    <br>
    <br><br><br>
    <!-- Footer Amélioré pour Devis PDF -->
    <footer style="text-align: center; margin-top: 40px; border-top: 2px solid #ddd; padding-top: 15px; font-size: 12px; color: #555;">
      <p style="margin: 10px 0; font-size: 10px; color: #888;">Si vous avez des questions ou souhaitez plus d'informations, n'hésitez pas à nous contacter.</p>
      <!-- Mention légale -->
      <p style="margin: 10px 0; font-size: 10px; color: #aaa;">&copy; 2024 MSS Consulting - Tous droits réservés.</p>
    </footer>
    
    
    </div>
    
 
  </div>

