<div class="container">
  <h1 style="margin-top:2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Réponse Devis</h1>
  <p style="color: gray;  text-align: center;">
    Entrez le code de réponse du devis reçu par e-mail lors de la demande.
  </p>
  <hr style="width: 20rem;  margin: 15px auto;">

  <div *ngIf="!devis" class="mt-3 devis-container">
    <div class="card custom-card">
      <div class="card-body">
  <form [formGroup]="reponseDevisForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="codeReponseDevis">Code Réponse Devis:</label>
      <input type="text" id="codeReponseDevis" formControlName="codeReponseDevis" class="form-control" required>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>

  <div *ngIf="errorMessage" class="alert alert-danger mt-3">
    {{ errorMessage }}
  </div>
</div>
</div>
</div>
  <div *ngIf="devis" class="mt-3 devis-container">
    <h2 class="section-title">Détails du Devis</h2>

   
    
    <div class="btn-container">
      <button [routerLink]="['/devis-pdf', devis.codeReponseDevis]" class="styled-btn">Télécharger en PDF</button>
      <button (click)="openRegisterModal()" class="styled-btn">Devenir un client</button>
    </div>
    

    <div class="card custom-card">
      <div class="card-body">
        <div class="devis-details">
          <p><span class="detail-label">Date Demande:</span> {{ devis.dateDemande }}</p>
          <p><span class="detail-label">Statut:</span> {{ devis.statut }}</p>
          <p><span class="detail-label">Ville Départ:</span> {{ devis.villeDepart }}</p>
          <p><span class="detail-label">Ville Arrivée:</span> {{ devis.villeArrivee }}</p>
          <p><span class="detail-label">Nom Client:</span> {{ devis.nomClient }}</p>
          <p><span class="detail-label">Téléphone Client:</span> {{ devis.telephoneClient }}</p>
          <p><span class="detail-label">Date Livraison Souhaitée:</span> {{ devis.dateLivraisonSouhaitee }}</p>
          <div class="info-section">
            <h3>Lignes d'envoi</h3>
            <div *ngFor="let ligne of devis.ligneEnvoi; let i = index" class="ligne-envoi">
              <div class="info-block">
                <strong>Marchandise {{ i + 1 }}:</strong>
                <hr>
                <p><span>Description Marchandise:</span> {{ ligne.descriptionMarchandise }}</p>
                <p><span>Type d'Emballage:</span> {{ ligne.typeEmbalage }}</p>
                <p><span>Quantité:</span> {{ ligne.quantite }}</p>
                <p><span>Poids:</span> {{ ligne.poids }} kg</p>
                <p><span>Longueur:</span> {{ ligne.longeur }} cm</p>
                <p><span>Largeur:</span> {{ ligne.largeur }} cm</p>
                <p><span>Hauteur:</span> {{ ligne.hoteur }} cm</p>
                <p><span>Produit Dangereux:</span> {{ ligne.produitDangereux }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="reponseDevis" class="response-container">
    <h2 class="section-title">Réponse Devis</h2>
    <div *ngFor="let resDevis of reponseDevis" class="card custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="info-section">
              <p class="card-text">Date Réponse: {{ resDevis.dateReponse | date:'medium' }}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="user-section">
              <p class="card-text">Transporteur: {{ resDevis.user.firstName }} {{ resDevis.user.lastName }}</p>
              <p class="card-text">Email: {{ resDevis.user.email }}</p>
              <p class="card-text">Adresse: {{ resDevis.user.address }}</p>
              <p class="card-text">Ville: {{ resDevis.user.city }}</p>
            </div>
          </div>
        </div>
        <div class="prix-section">
          <span class="prix-label">Prix : </span>
          <span class="prix"> {{ resDevis.prix }} DT</span>
          <button class="btn btn-outline-secondary btn-sm float-right" (click)="confirm(resDevis)">Confirmer</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!reponseDevis && devis && !errorMessage" class="mt-3">
    <p>Pas de réponses pour le moment.</p>
  </div>
</div>
