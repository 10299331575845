import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './how-does-it-work.component.html',
  styleUrls: ['./how-does-it-work.component.css']
})
export class HowDoesItWorkComponent implements OnInit {
  sections = [
    {
      number: 1,
      title: 'Formulaire de demande de devis',
      description: 'L\'utilisateur passager est tenu de compléter le formulaire de demande de devis en fournissant les informations nécessaires, telles que les détails concernant la marchandise, le lieu de départ, la destination, et les spécifications éventuelles du transport. Une fois le formulaire rempli, il a la possibilité de sélectionner un ou plusieurs transporteurs parmi ceux disponibles, en fonction de leurs services proposés, leurs tarifs ou leur réputation. Cette étape permet de transmettre la demande de devis aux transporteurs choisis pour obtenir des propositions adaptées à ses besoins spécifiques.',
      imageUrl: './assets/guide/step-2.png'
    },
    {
      number: 1.1,
      title: 'Réception d’une alerte avec le code de réponse au devis',
      description: 'L’utilisateur reçoit une alerte contenant le code de réponse au devis. En cliquant sur cette alerte, il est redirigé vers une page où il peut consulter sa demande.',
      imageUrl: './assets/guide/step-1.1.png'
    }
    ,
    {
      number: 1.2,
      title: 'Consultation de la demande de devis',
      description: 'L’utilisateur accède à une interface dédiée où il peut consulter tous les détails de sa demande de devis.',
      imageUrl: './assets/guide/step-1.2.png'
    }
    ,
    {
      number: 2,
      title: 'Génération et envoi du code de réponse au devis par email',
      description: 'Une fois la demande de devis complétée et transmise aux transporteurs sélectionnés, le système procède automatiquement à la génération d\'un code unique de réponse. Ce code sert d\'identifiant pour la consultation des devis reçus. Une fois généré, ce code est immédiatement envoyé à l\'utilisateur par courrier électronique à l\'adresse qu\'il a renseignée lors de sa demande. Ce processus garantit que l\'utilisateur dispose d\'un moyen sécurisé et pratique pour accéder aux offres de transporteurs et sélectionner celle qui répond le mieux à ses attentes.',
      imageUrl: './assets/guide/step-3.png' 
    },
    {
      number: 2.1,
      title: 'Le transporteur sélectionné reçoit la demande de devis',
      description: 'Le transporteur se connecte à son interface où une nouvelle demande de devis est affichée. Il peut alors consulter les détails de cette demande et commencer à y répondre.',
      imageUrl: './assets/guide/step-2.1.png'
    },
    {
      number: 3,
      title: 'Réponse des transporteurs à la demande de devis',
      description: 'Les transporteurs sélectionnés reçoivent automatiquement la demande de devis, contenant tous les détails nécessaires, tels que la marchandise, les lieux de départ et d’arrivée, ainsi que les spécifications éventuelles. Ils analysent ces informations pour préparer une proposition tarifaire adaptée aux besoins exprimés par l’utilisateur. Une fois leur offre finalisée, ils la transmettent au système, permettant à l’utilisateur de consulter et de comparer les propositions.',
      imageUrl: './assets/guide/step-4.png'
    },    
    {
      number: 4,
      title: 'Consultation et sélection du transporteur',
      description: 'L\'utilisateur accède aux réponses des transporteurs en saisissant le code unique reçu par email dans l\'interface prévue à cet effet. Une fois les propositions affichées, il peut examiner les détails de chaque devis, comme le prix, les conditions de transport, et les délais. Après comparaison, l\'utilisateur valide son choix en confirmant le transporteur qui correspond le mieux à ses besoins.',
      imageUrl: './assets/guide/step-5.png'
    },
    {
      number: 4.1,
      title: 'Impression de la réponse au devis en PDF',
      description: 'L’utilisateur peut imprimer le devis confirmé au format PDF pour l’enregistrer ou le partager.',
      imageUrl: './assets/guide/step-4.1.png'
    }
,    
    {
      number: 5,
      title: 'Création de la mission par le transporteur',
      description: 'Après avoir reçu la confirmation de l’utilisateur, le transporteur initie le processus en créant une mission pour le transport demandé. Il sélectionne un véhicule adapté aux spécifications de la commande ainsi qu’un chauffeur disponible pour assurer le bon déroulement de la mission.',
      imageUrl: './assets/guide/step-6.png'
    },
    {
      number: 6,
      title: 'Notification d’information sur la nouvelle mission',
      description: 'Le chauffeur reçoit une notification pour l’informer de la nouvelle mission, à la fois par SMS sur son mobile et via son espace personnel sur le site web.',
      imageUrl: './assets/guide/step-5.1.png'
    },
    {
      number: 6.1,
      title: 'Confirmation de la mission par le chauffeur',
      description: 'Le chauffeur confirme la prise en charge de la mission via son interface utilisateur.',
      imageUrl: './assets/guide/step-5.2.png'
    },
    {
      number: 6.2,
      title: 'Mise à jour des différents statuts de la mission par le chauffeur',
      description: 'Le chauffeur met à jour les statuts de la mission, de son démarrage jusqu’au retour du véhicule au parking.',
      imageUrl: './assets/guide/step-6.png'
    },
    {
      number: 6.3,
      title: 'Notification des changements de statut de la mission pour le transporteur',
      description: 'Le transporteur est informé en temps réel des changements de statut de la mission, tels que "Démarrée", "En route", ou "Livrée", grâce à des notifications dans son espace personnel.',
      imageUrl: './assets/guide/step-5.3.png'
    },
    {
      number: 7,
      title: 'Suivi en temps réel par le client',
      description: 'Le client peut suivre en temps réel les différentes étapes de sa commande, comme "Marchandise collectée", "En route", ou "Livrée". Ce suivi lui offre une visibilité complète sur l\'avancement de la mission et garantit une meilleure expérience utilisateur.',
      imageUrl: './assets/guide/step-8.png' 
    },
    {
      number: 8,
      title: 'Suivi en temps réel des véhicules par le transporteur',
      description: 'Le transporteur dispose d\'un outil de suivi en temps réel qui lui permet de localiser ses véhicules à tout moment. Cette fonctionnalité facilite la gestion des missions et améliore la coordination des opérations.',
      imageUrl: './assets/guide/step-9.png' 
    }
  ];
  

  ngOnInit() {
    console.log(this.sections);
  }

  openPopup(number: number) {
    const section = this.sections.find(section => section.number === number);
  
    if (section) {
      Swal.fire({
        title: section.title,
        text: section.description,
        imageUrl: section.imageUrl,
        imageAlt: section.title,
        confirmButtonText: 'Fermer',
        width: '700px' 
      });
    }
  }
  
  
  constructor() { }


}
