import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './Components/register/register.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './Components/login/login.component';
import { HomePageComponent } from './Components/home-page/home-page.component';
import { TestComponent } from './Components/test/test.component';
import { ChouffeurListComponent } from './Components/chouffeur-list/chouffeur-list.component';
import { ChouffeurFormComponent } from './Components/chouffeur-form/chouffeur-form.component';
import { UpdateChouffeurComponent } from './Components/update-chouffeur/update-chouffeur.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms'; 
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FileUploadComponent } from './Components/file-upload/file-upload.component';
import { DetailsChauffeurComponent } from './Components/details-chauffeur/details-chauffeur.component';
import { NavbarComponent } from './Components/navbar/navbar.component';
import { SidebarComponent } from './Components/sidebar/sidebar.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { VehiculesComponent } from './Components/vehicules/vehicules.component';
import { StatistiquesVehiculesComponent } from './Components/statistiques-vehicules/statistiques-vehicules.component';
import { DevisFormClientComponent } from './Components/devis-form-client/devis-form-client.component';
import { ListDemandeDevisComponent } from './Components/list-demande-devis/list-demande-devis.component';
import { ReponseDevisComponent } from './Components/reponse-devis/reponse-devis.component';
import { ReponseDevisCodeComponent } from './Components/reponse-devis-code/reponse-devis-code.component';
import { AddResponseDevisComponent } from './Components/add-response-devis/add-response-devis.component';
import { ConfirmDevisComponent } from './Components/confirm-devis/confirm-devis.component';
import { AddMissionComponent } from './Components/add-mission/add-mission.component';
import { MissionTransporteurComponent } from './Components/mission-transporteur/mission-transporteur.component';
import { StatistiqueMissionComponent } from './Components/statistique-mission/statistique-mission.component';
import { MissionChauffeurComponent } from './Components/mission-chauffeur/mission-chauffeur.component';
import { HowDoesItWorkComponent } from './Components/how-does-it-work/how-does-it-work.component';
import { LandingPageComponent } from './Components/landing-page/landing-page.component';
import { ListTransporteursComponent } from './Components/list-transporteurs/list-transporteurs.component';
import { MissionHistoryComponent } from './Components/mission-history/mission-history.component';
import { ContactComponent } from './Components/contact/contact.component';
import { ProfilComponent } from './Components/profil/profil.component';
import { FooterComponent } from './Components/footer/footer.component';
import { MessageTransporteurComponent } from './Components/message-transporteur/message-transporteur.component';
import { MessageChauffeurComponent } from './Components/message-chauffeur/message-chauffeur.component';
import { GuideComponent } from './Components/guide/guide.component';
import { CreateMissionWithDevisComponent } from './Components/create-mission-with-devis/create-mission-with-devis.component';
import { DevisFormClientTrasporteurSelectedComponent } from './Components/devis-form-client-trasporteur-selected/devis-form-client-trasporteur-selected.component';
import { MissionsClientComponent } from './Components/missions-client/missions-client.component';
import { ListTransporteursAdminComponent } from './Components/list-transporteurs-admin/list-transporteurs-admin.component';
import { ContactsComponent } from './Components/contacts/contacts.component';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    HomePageComponent,
    TestComponent,
    ChouffeurListComponent,
    ChouffeurFormComponent,
    UpdateChouffeurComponent,
    FileUploadComponent,
    DetailsChauffeurComponent,
    NavbarComponent,
    SidebarComponent,
    ResetPasswordComponent,
    VehiculesComponent,
    StatistiquesVehiculesComponent,
    DevisFormClientComponent,
    ListDemandeDevisComponent,
    ReponseDevisComponent,
    ReponseDevisCodeComponent,
    AddResponseDevisComponent,
    ConfirmDevisComponent,
    AddMissionComponent,
    MissionTransporteurComponent,
    StatistiqueMissionComponent,
    MissionChauffeurComponent,
    HowDoesItWorkComponent,
    LandingPageComponent,
    ListTransporteursComponent,
    MissionHistoryComponent,
    ContactComponent,
    ProfilComponent,
    FooterComponent,
    MessageTransporteurComponent,
    MessageChauffeurComponent,
    GuideComponent,
    CreateMissionWithDevisComponent,
    DevisFormClientTrasporteurSelectedComponent,
    MissionsClientComponent,
    ListTransporteursAdminComponent,
    ContactsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    Ng2SearchPipeModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
